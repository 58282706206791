import { CertificationSettingRequirements } from "components";

export function CertificationSettings() {
  return (
    <>
      <div className="content-admin">
        <div className="heading">
          <h2 className="block-title">Certification Settings</h2>

          <CertificationSettingRequirements />
        </div>
      </div>
    </>
  );
}
