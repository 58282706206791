import { ROUTE } from "variables";
import { Button } from "react-bootstrap";
import { CertificationService } from "services/certification";

export function ClearCertification({ ABN }) {
  const clearCert = async () => {
    await CertificationService.clearCertification(ABN);
    window.location.href = `${ROUTE.ROOT}?ABN=${ABN}`;
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={clearCert}>
        Clear certification
      </Button>
    </div>
  );
}
