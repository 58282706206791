// import { authBanner as banner } from "assets";
import { spinner } from "assets";
import { FormInput, NativeTooltip, Spinner } from "components";
import { ROUTE } from "variables";
import { useLoadingState } from "middleware";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services";

import "./login.scss";

const initialState = {
  email: "",
  password: "",
};

const initialValidation = {
  email: "NA",
  password: "success",
  login: "NA",
};

const initialErrorTxt = {
  email: "Please enter a valid email address.",
  password: "Please enter your password.",
  login: "Login failed. Please try again.",
};

const validationRules = ["email", "password"];

export function Login({ onSignin = () => {} }) {
  const [loginState, setLoginState] = useState(initialState);
  const [validation, setValidation] = useState(initialValidation);
  const [errorTxt, setErrorTxt] = useState(initialErrorTxt);
  const [disabled, setDisabled] = useState(true);
  const { loading, setLoading } = useLoadingState();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, []);

  function handleInput(event) {
    setLoginState({
      ...loginState,
      [event.target.name]: event.target.value,
    });
  }

  const pSetValidation = (name, value) => {
    if (name === "password" && value === "error") {
      return;
    }
    const validationResult = { ...validation, [name]: value };
    setValidation(validationResult);
    setDisabled(
      !validationRules.every((prop) => validationResult[prop] === "success")
    );
  };

  const validateSubmit = (event) => {
    setLoading(true);

    event.preventDefault();

    const canSubmit = validationRules.every((prop) => !!loginState[prop]);

    if (!canSubmit) {
      if (!loginState.password) {
        setValidation({
          ...validation,
          password: "error",
        });
      }

      setDisabled(true);
      return;
    } else {
      login().then(() => {
        setLoading(false);
      });
    }
  };

  async function login() {
    try {
      const { email, password } = loginState;
      await AuthService.signIn(
        email,
        password,
        () => navigate(ROUTE.ROOT),
        onSignin,
        () =>
          navigate(ROUTE.MFA_VERIFY, {
            state: {
              email,
              password,
            },
          }),
        () => navigate(ROUTE.MFA_SETUP)
      );
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        setErrorTxt({
          ...errorTxt,
          login: error.message,
        });
        setValidation({
          ...validation,
          login: "error",
        });
      } else if (error.code === "UserNotFoundException") {
        setErrorTxt({
          ...errorTxt,
          login: "This user does not exist.",
        });
        setValidation({
          ...validation,
          login: "error",
        });
      }
      console.error("Error logging in:", error);
    }
  }

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="login-container">
        <div className="auth-title heading2">Login</div>
        <div className="subtitle">
          New to CyberCert?{" "}
          <Link to={ROUTE.REGISTER}>Claim your organisation's account</Link>
        </div>

        <Form>
          <div className="form-validation error">
            <div className="validation-txt">
              {!!errorTxt.email && validation.email === "error" && (
                <p className="v-text error">{errorTxt.email}</p>
              )}
              {!!errorTxt.password && validation.password === "error" && (
                <p className="v-text error">{errorTxt.password}</p>
              )}
              {!!errorTxt.login && validation.login === "error" && (
                <p className="v-text error">{errorTxt.login}</p>
              )}
            </div>
          </div>

          <FormInput
            controlId="loginEmail"
            label="Work Email Address"
            type="email"
            name="email"
            maxLength="240"
            // placeholder="Email Address"
            onChange={handleInput}
            errorTxt={""}
            pValidation={validation.email}
            pSetValidation={(value) => pSetValidation("email", value)}
            required
          />

          <div className={`form-validation ${validation.password}`}>
            <FormInput
              controlId="loginPassword"
              label="Password"
              type="password"
              name="password"
              // placeholder="Password"
              errorTxt={""}
              onChange={handleInput}
              pValidation={validation.password}
              pSetValidation={(value) => pSetValidation("password", value)}
              required
            />
          </div>

          <div className="forgot-password">
            <Link to={ROUTE.RESET_PASSWORD}>Reset your password</Link>
          </div>

          <div className="f-row-spread login-option">
            <Button
              className="btn btn-warning-gd"
              onClick={validateSubmit}
              disabled={disabled}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
