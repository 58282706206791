import { parseDate } from "services";

export function ClaimABN({ item }) {
  return (
    <div>
      <strong className="org-name">
        <span>{item?.name}</span>
      </strong>{" "}
      ({item?.ABN}) has been claimed by <a className="link">{item?.email}</a>
    </div>
  );
}
