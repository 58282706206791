import { ROUTE } from "variables";
import { NavLink } from "react-router-dom";
import "./index.scss";

export function Navbar() {
  return (
    <>
      <div className="container">
        <NavLink end to={ROUTE.ROOT}>
          Dashboard
        </NavLink>{" "}
        <NavLink to={ROUTE.ACTIVITIES}>Activity Feed</NavLink>
        {/* <NavLink to={ROUTE.ACCOUNTS}>Accounts</NavLink>
        <NavLink to={ROUTE.SALES}>Sales</NavLink> */}
        <NavLink to={ROUTE.CERTIFICATIONS}>Certifications</NavLink>
        {/* <NavLink to={ROUTE.COMMISSIONS}>Commissions</NavLink>
        <NavLink to={ROUTE.CAMPAIGNS}>Campaigns</NavLink> */}
        <NavLink to={ROUTE.REPORTS}>Reports</NavLink>
        <NavLink to={ROUTE.SETTINGS}>Settings</NavLink>
      </div>
    </>
  );
}
