import { getCertificationProgress, getCustomer, getUserByABN } from "api";
import { ROUTE } from "variables";
import { useLoadingState } from "middleware";
import { Button } from "react-bootstrap";
import { CertificationService } from "services/certification";

export function PassAudit({ ABN }) {
  const { setLoading } = useLoadingState();

  const passAudit = async () => {
    setLoading(true);

    const user = await getUserByABN(ABN);
    const customer = await getCustomer(ABN);

    const cert = customer?.certification;

    const certProgress = cert.progress;

    if (certProgress?.step !== 5) {
      console.error("Illegal state.");
      setLoading(false);
      return;
    }

    await CertificationService.passAudit(certProgress);

    window.location.href = `${ROUTE.ROOT}?ABN=${ABN}`;

    setLoading(false);
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={passAudit}>
        Pass Audit
      </Button>
    </div>
  );
}
