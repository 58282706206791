import { delete_account } from "assets";
import { parseDate } from "services";
import { ClaimABN } from "./claim-abn";
import { CompleteCertificate } from "./complete-certification";
import { PurchaseLevel } from "./purchase-level";
import { SendAttestation } from "./send-attestation";
import { SignAttestation } from "./sign-attestation";
import { StartCertificate } from "./start-certification";
import { SubmitAttestation } from "./submit-attestation";

export function RecentActivityItem({ item, deleteFn = () => {} }) {
  const orgNameLimit = 50;
  const truncatedItem = {
    ...item,
    name:
      item?.name?.length > orgNameLimit
        ? item?.name?.substring(0, orgNameLimit - 3) + "..."
        : item?.name,
  };

  const RecentActivityItem_ = (ClassifiedItem) => {
    return (
      <RecentItemLayout item={item} deleteFn={deleteFn}>
        <ClassifiedItem item={truncatedItem} />
      </RecentItemLayout>
    );
  };

  return (
    <>
      {(() => {
        switch (item.type) {
          case "CLAIM_ABN":
            return RecentActivityItem_(ClaimABN);
          case "PURCHASE_LEVEL":
            return RecentActivityItem_(PurchaseLevel);
          case "COMPLETE_CERTIFICATE":
            return RecentActivityItem_(CompleteCertificate);
          case "START_CERTIFICATE":
            return RecentActivityItem_(StartCertificate);
          case "SUBMIT_ATTESTATION":
            return RecentActivityItem_(SubmitAttestation);
          case "SEND_ATTESTATION":
            return RecentActivityItem_(SendAttestation);
          case "SIGN_ATTESTATION":
            return RecentActivityItem_(SignAttestation);
          default:
            // return <UncategorisedActivity item={truncatedItem} />;
            return <></>;
        }
      })()}
    </>
  );
}

function RecentItemLayout({ item, deleteFn, children }) {
  return (
    <div className="content-admin admin-page recent-item">
      {children}

      <div className="options">
        <div>{parseDate(item?.createdAt)}</div>

        <img
          src={delete_account}
          alt="Delete account"
          onClick={() => deleteFn(item)}
        />
      </div>
    </div>
  );
}
