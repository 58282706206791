import { capitalize } from "services/utils";

/** GUID for accessing the web services */
const GUID = "d4d40a79-cbfa-4c87-9b5f-26df821c4980";
/** Json Lookup Web service endpoint */
const ABN_LOOKUP_ENDPOINT = "https://abr.business.gov.au/json/AbnDetails.aspx";
/** Keyword for parsing web service's response */
const CALLBACK_KEYWORD = "CYBERCERT_CALLBACK";

/**
 * Perform ABN lookup on given ABN number
 *
 * @param {string} abnInput
 */
const lookup = async (abnInput) => {
  /** FOR BETA */
  // if (!lookupForApproveABN(abn)) {
  //   return {
  //     result: undefined,
  //     error: "Your ABN isn't approved for beta testing. Contact us.",
  //   };
  // }
  /** END: FOR BETA */

  const abn = abnInput.replace(/ /g, "");
  if (abn.length !== 11 || !/^\d+$/.test(abn)) {
    return {
      result: undefined,
      error: "Please enter a valid ABN number.",
    };
  }

  const query = `${ABN_LOOKUP_ENDPOINT}?callback=${CALLBACK_KEYWORD}&abn=${abn}&guid=${GUID}`;
  const response = await fetch(query).then((response) => response.text());
  // text response is in the form
  // `CYBERCERT_CALLBACK({[lookup_results]})`
  // which supposed to be a callback
  // but we only need to extract the json part
  // and parse the content
  const jsonResponse = response.slice(CALLBACK_KEYWORD.length + 1, -1);

  const jsonResult = JSON.parse(jsonResponse);

  if (!jsonResult.Abn) {
    return { result: undefined, error: "No results found. Please try again." };
  }

  return { result: jsonResult };
};

const lookupOrganisation = async (abn) => {
  const result = await lookup(abn);

  if (!result.result) {
    return result;
  }

  const { EntityName, Abn, AddressPostcode, AddressState, EntityTypeName } =
    result.result;

  return {
    result: {
      ABN: Abn,
      name: capitalize(EntityName.toLowerCase()),
      industry: EntityTypeName.toLowerCase(),
      addressState: AddressState,
      addressPostcode: AddressPostcode,
      supplierCertCodes: [],
    },
  };
};

export const ABNService = {
  lookup,
  lookupOrganisation,
};
