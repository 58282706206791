import { downloadRequirementsFile, uploadeloadRequirementsFile } from "api";
import { useLoadingState } from "middleware";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function CertificationSettingRequirements() {
  const { setLoading } = useLoadingState();
  const [showUploadMenu, setShowUploadMenu] = useState(false);
  const [uploadFileData, setUploadFileData] = useState();
  const [uploadMultipleLock, setUploadMultipleLock] = useState(true);
  const [updateReq, setUpdateReq] = useState(false);

  const download = async () => {
    setLoading(true);

    const result = await downloadRequirementsFile();

    console.log(result);

    window.open(result, "_blank");

    setLoading(false);
  };

  const handleFile = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

    try {
      setLoading(true);

      const base64 = await toBase64(file);
      setUploadFileData(base64);

      handleOpenUploadMenu();
    } catch (e) {
      console.log(e);
    }

    setLoading(false);

    event.target.value = null;
  };

  const handleOpenUploadMenu = () => {
    setShowUploadMenu(true);
  };

  const handleHideUploadMenu = () => {
    setShowUploadMenu(false);
  };

  const uploadSingle = () => {
    handleHideUploadMenu();

    upload({
      multiple: false,
    });
  };

  const uploadMultiple = () => {
    handleHideUploadMenu();

    upload({
      multiple: true,
    });
  };

  const upload = async ({ multiple = false }) => {
    setLoading(true);

    if (!!uploadFileData) {
      uploadeloadRequirementsFile({
        data: uploadFileData,
        multiple,
        update: updateReq === true
      });

      setUploadFileData(null);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <>
      <div>
        <Button variant="btn btn-warning-gd" onClick={download}>
          Download requirements
        </Button>

        <div>
          <input
            type="file"
            accept="json"
            multiple={false}
            onChange={handleFile}
          />
          <p>Upload new requirements</p>
        </div>
      </div>

      <Modal show={showUploadMenu} onHide={handleHideUploadMenu}>
        <Modal.Header closeButton>
          <Modal.Title>Upload New Requirements</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Upload requirements for this app or upload for all environments.
          </p>

          <p className="req-upload-update-text">Also update existing requirements' descriptions (only the current app)?</p>

          <input className="req-upload-update-checkbox" type="checkbox" name="name" id="id" onClick={(e) => {
            setUpdateReq(e.target.checked);
          }} />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant={uploadMultipleLock === true ? "secondary" : "primary"}
            onClick={() => {
              if (uploadMultipleLock === true) {
                setUploadMultipleLock(false);
              } else {
                uploadMultiple();
              }
            }}
          >
            Upload for ALL app
          </Button>

          <Button variant="primary" onClick={uploadSingle}>
            Upload for THIS app
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
