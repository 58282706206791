import { API } from "aws-amplify";

export const MAINTENANCE_INTERVAL = {
  YEAR: "year",
  QUARTER: "quarter",
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  HOUR: "hour",
  MINUTE: "minute",
};

export const scheduleMaintenance = async ({
  time = 12,
  interval = MAINTENANCE_INTERVAL.HOUR,
}) => {
  const myInit = {
    queryStringParameters: {
      time,
      interval,
    },
    body: {
      time,
      interval,
    },
  };
  const result = await API.post("maintenance", `/schedule`, myInit);
  // console.log(result);
  return result;
};

export const cancelMaintenance = async () => {
  const myInit = {};
  const result = await API.post("maintenance", `/cancel`, myInit);
  console.log(result);
  return result;
};
