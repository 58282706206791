import { NavBreadcrumb } from "components/breadcrumbs/nav-breadcrumb-list";

export function NavBreadcrumbList({ items }) {
  return (
    <ul className="status-label-list dark">
      {items.map((item) => (
        <NavBreadcrumb item={item} key={item.to} />
      ))}
    </ul>
  );
}
