import {
  deleteCertification,
  deleteCertificationProgress,
  deleteRequirement,
  getCertRequirements,
  getCertificationsByABN,
  getOrganisation,
  getRequirementsByABN,
  passCertificationAudit,
  updateCustomer,
  updateOrganisation
} from "api";
import {
  CERTIFICATION_STEP_TITLES
} from "variables";

/**
 * Get current certification progress for given organisation
 * @param {*} abn organisation business number
 */
const getCurrentCertificationProgress = (abn) =>
  getOrganisation(abn).then(
    (organisation) => organisation?.certificationProgress
  );

const getRequirementsForLevel = async (level) => {
  if (!level) {
    return [];
  }
  // temp
  return (await getCertRequirements(level))?.data;
};

/**
 * Get the next step
 *
 * @param {*} level
 * @param {*} step
 * @returns
 */
const getCertificationProgressNextStep = (level, step) => {
  if (level < 4 && step === 4) {
    return step + 2;
  }
  return step + 1;
};

const getCertStepTitleFor = (progress) => {
  return CERTIFICATION_STEP_TITLES[getActualCurrentStep(progress)];
};

const getActualTotalSteps = (progress) => {
  return progress?.level < 4 ? 5 : 6;
};

const getActualCurrentStep = (progress) => {
  return progress?.level < 4 && progress?.current > 4
    ? progress?.current - 1
    : progress?.current;
};

const clearCertification = async (ABN) => {
  const certs = (await getCertificationsByABN(ABN))?.items || [];

  // console.log(certProgresses);

  await Promise.all(
    certs.map(async (cert) => {
      await deleteCertification({ id: cert.id });
      await deleteCertificationProgress({ id: cert?.progress?.id });
    })
  );

  const requirements = (await getRequirementsByABN(ABN))?.items || [];

  // console.log(requirements);

  await Promise.all(
    requirements.map((requirement) => deleteRequirement({ id: requirement.id }))
  );

  await updateOrganisation({
    ABN,
    certificationLevel: 0,
  });

  await updateCustomer({
    ABN,
    certificationId: null,
  });
};

const passAudit = ({ id }) => passCertificationAudit({ id });

export const CertificationService = {
  clearCertification,
  getCurrentCertificationProgress,
  getCertificationProgressNextStep,
  getCertStepTitleFor,
  getActualTotalSteps,
  getActualCurrentStep,
  getRequirementsForLevel,
  passAudit,
};
