import { updateActiveApp } from "api";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function AdminAppSettingMonthlyReport({ app, navigate = () => {} }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirm = async () => {
    await updateActiveApp({
      enableMonthlyReport: !app?.enableMonthlyReport,
    });
    setShow(false);
    navigate(0);
  };

  return (
    <>
      <div>
        <p>
          Allow monthly report:{" "}
          {app?.enableMonthlyReport ? "Enabled" : "Disabled"}
        </p>

        <Button variant="btn btn-warning-gd" onClick={handleShow}>
          Toggle
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Monthly Report</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {app?.enableMonthlyReport ? "Disallow" : "Allow"} monthly report.
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
