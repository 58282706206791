import { spinner } from "assets";
import { FormInput, Spinner } from "components";
import { LinkArrowSvg } from "components/svgs/link-arrow";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthService } from "services";

export function ResendConfirmationEmail() {
  const [showPanel, setShowPanel] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [resendOk, setResendOk] = useState("na");
  const [email, setEmail] = useState();

  const handleClose = () => {
    setShowPanel(false);
    setEmail(null);
    setResendOk("na");
  };
  const handleShow = () => {
    setShowPanel(true);
    setResendOk("na");
  };

  const resend = async () => {
    setInternalLoading(true);

    if (!!email) await AuthService.resendConfirmationCode(email);

    handleClose();

    setInternalLoading(false);
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={handleShow}>
        <LinkArrowSvg />
        Resend confirmation email
      </Button>

      <Modal show={showPanel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Resend registration confirmation email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {internalLoading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="email"
            label="Search by email"
            type="search"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <div className={`form-validation ${resendOk}`}>
            <div className="validation-txt">
              <p className="v-text success">Resend success.</p>
              <p className="v-text error">Resend failed.</p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={resend} disabled={!email}>
            Resend
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
