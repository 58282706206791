import { Footer, Header } from "layouts";

export function LayoutWrapper({ children }) {
  return (
    <>
      <Header />

      {children}

      <Footer />
    </>
  );
}
