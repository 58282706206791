import { CERTIFICATION_INFO } from "variables";
import { parseDate } from "services";

export function PurchaseLevel({ item }) {
  return (
    <div>
      <strong className="org-name">
        <span>{item?.name}</span>
      </strong>{" "}
      ({item?.ABN}) purchased{" "}
      <a className="link">
        Level {item?.level} - {CERTIFICATION_INFO[item?.level].marketingName}
      </a>
    </div>
  );
}
