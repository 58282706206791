import { makeGetFunction } from "api/utils";
import { listCertBundles as listAPI } from "graphql/queries";

export const listCertBundles = async () =>
  (
    await makeGetFunction({
      name: "listCertBundles",
      query: listAPI,
    })()
  )?.items || [];
