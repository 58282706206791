import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export const ConfirmPopup = forwardRef((props, ref) => {
  const {
    title,
    body,
    confirmFn,
    confirmTxt = "Confirm",
    cancelTxt = "Cancel",
  } = props;

  const [showConfirm, setShowConfirm] = useState(false);

  const handleClose = () => {
    setShowConfirm(false);
  };
  const handleShow = () => {
    setShowConfirm(true);
  };

  useImperativeHandle(ref, () => ({
    show() {
      handleShow();
    },

    close() {
      handleClose();
    },
  }));

  return (
    <>
      <Modal show={showConfirm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {cancelTxt}
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              confirmFn();
            }}
          >
            {confirmTxt}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
