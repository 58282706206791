import { showPasswd } from "assets";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Asterisk } from "../required-asterisk";

import "./form-input.scss";

export function FormPasswordInput({
  controlId,
  label,
  name,
  placeholder,
  onChange,
  required,
  disabled,
  successTxt = "",
  errorTxt = "Field is required.",
  pValidation,
  pSetValidation,
  autoComplete,
}) {
  let [validation, setValidation] = useState("NA");
  const [showPwd, setShowPwd] = useState(false);

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  function toggleShowPwd(event) {
    event.preventDefault();
    setShowPwd(!showPwd);
  }

  const validateInput = (event) => {
    if (!!event.target.value) {
      setValidation("success");
    } else {
      setValidation("error");
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>
      <Form.Group
        className="mb-3 full-input passwd-input-group"
        controlId={controlId}
      >
        <Form.Label className="form-label">
          <div>{label}</div>
          {required && <Asterisk />}
        </Form.Label>

        <div className="passwd-input">
          <Form.Control
            className="form-input"
            type={showPwd ? "text" : "password"}
            name={name}
            autoComplete={autoComplete}
            placeholder={placeholder}
            onChange={validateInput}
            onBlur={validateInput}
            onKeyPress={(event) => {
              if (event.code === "Enter") {
                event.preventDefault();
              }
            }}
            disabled={disabled}
          />

          {disabled ? (
            <></>
          ) : (
            <button
              className="show-passwd-btn"
              onClick={toggleShowPwd}
              tabIndex="-1"
            >
              <img src={showPasswd} alt="cscau-show-password" />
              {/* Show */}
            </button>
          )}
        </div>
      </Form.Group>
    </div>
  );
}
