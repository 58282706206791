import { ROUTE } from "variables";
import { useAppSessionState } from "middleware";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services";

export function RequireNoAuth({ children }) {
  const { setSession } = useAppSessionState();
  const navigate = useNavigate();

  const checkAccountState = () => {
    AuthService.checkLoginState().then((sess) => {
      setSession(sess);
      if (!!sess) {
        navigate(ROUTE.ROOT);
      }
    });
  };

  useEffect(() => {
    checkAccountState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}
