import { getActiveApp, onUpdateActiveApp } from "api";
import {
  AdminAppSettingMaintenanceMode,
  AdminAppSettingMonthlyReport,
} from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function AppSettings() {
  const [app, setApp] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getActiveApp().then((a) => {
      console.log(a);
      setApp(a);
    });

    return onUpdateActiveApp({
      callbackFn: setApp,
    });
  }, []);

  return (
    <>
      <div className="content-admin">
        <div className="heading">
          <h2 className="block-title">App Configuration</h2>
        </div>

        <AdminAppSettingMaintenanceMode app={app} navigate={navigate} />

        <hr />

        <AdminAppSettingMonthlyReport app={app} navigate={navigate} />
      </div>
    </>
  );
}
