import { updateCustomer } from "graphql/mutations";
import { Button } from "react-bootstrap";
import { ROUTE } from "variables";

export function RemoveGuide({ ABN }) {
  const removeGuide = async () => {
    await updateCustomer({
      ABN,
      guidePurchased: false,
    });

    window.location.href = `${ROUTE.ROOT}?ABN=${ABN}`;
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={removeGuide}>
        Remove Guide Purchase
      </Button>
    </div>
  );
}
