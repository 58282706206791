import { makeGetFunction } from "api/utils";
import { getUser as getAPI, listUsers } from "graphql/queries";

export const getUser = (id) =>
  makeGetFunction({
    name: "getUser",
    query: getAPI,
    params: {
      id,
    },
  })();

export const getUserByEmail = async (email) => {
  if (!email) {
    return undefined;
  }

  let result = [];
  let nextToken;

  while (true) {
    let response = await makeGetFunction({
      name: "listUsers",
      query: listUsers,
      params: {
        filter: {
          email: {
            eq: email,
          },
        },
        nextToken,
      },
    })();

    const items = response?.items || [];
    result = [...result, ...items];
    nextToken = response?.nextToken;

    if (!nextToken) {
      break;
    }
  }

  return { items: result };
};

export const getUserByABN = async (ABN) => {
  if (!ABN) return undefined;
  let result = [];
  let nextToken;
  do {
    const res = await makeGetFunction({
      name: "listUsers",
      query: listUsers,
      params: {
        filter: {
          ABN: {
            eq: ABN,
          },
        },
        limit: 10000,
        nextToken,
      },
    })();
    result = [...result, ...res?.items];
    nextToken = res?.nextToken;
  } while (nextToken);
  return result;
};
