import { makeGetFunction } from "api/utils";
import {
  getCertification as getAPI,
  listCertifications as listAPI,
} from "graphql/queries";

export const getCertification = (id) =>
  makeGetFunction({
    name: "getCertification",
    query: getAPI,
    params: {
      id,
    },
  })();

export const getCertificationsByABN = (ABN, filter = {}) =>
  !!ABN
    ? makeGetFunction({
        name: "listCertifications",
        query: listAPI,
        params: {
          filter: {
            ABN: {
              eq: ABN,
            },
            ...filter,
          },
        },
      })()
    : undefined;

export const searchCertificationsByABN = (ABN, filter = {}) =>
  makeGetFunction({
    name: "listCertifications",
    query: listAPI,
    params: {
      filter: {
        ABN: {
          contains: ABN || "",
        },
        ...filter,
      },
      limit: 10000,
    },
  })();

export const searchCertificationsByID = (ID, filter = {}) =>
  makeGetFunction({
    name: "listCertifications",
    query: listAPI,
    params: {
      filter: {
        id: {
          contains: ID || "",
        },
        ...filter,
      },
    },
  })();
