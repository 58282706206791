import { Router } from "routes";
import { Provider } from "react-redux";
import { store } from "middleware";

import "./App.scss";

function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
