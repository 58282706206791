import { getActiveApp } from "api/functions";
import { makeUpdateFunction } from "api/utils";
import { updateApp } from "graphql/mutations";

export const updateActiveApp = async (input) => {
  const activeApp = await getActiveApp();

  return makeUpdateFunction({
    name: "updateApp",
    query: updateApp,
    params: {
      input: {
        ...input,
        id: activeApp?.id,
      },
    },
  })();
};

export const setActiveAppPrimeIDs = (primeIDs) =>
  updateActiveApp({
    primeIDs,
  });
