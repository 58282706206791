import { spinner } from "assets";
import { FormInput } from "components/form-input";
import { Spinner } from "components/loading";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function PartnerOptionRemove({
  input,
  cfm,
  handleInput,
  showPanel,
  closePanel,
  showCfmPanel,
  closeCfmPanel,
  process,
  confirm,
}) {
  const [loading, setLoading] = useState();
  const [err, setErr] = useState();

  const handleProcess = async () => {
    setLoading(true);
    setErr(null);

    const result = await process();
    setErr(result);

    setLoading(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    setErr(null);

    const result = await confirm();
    setErr(result);

    setLoading(false);
  };

  return (
    <>
      <Modal show={showPanel} onHide={closePanel}>
        <Modal.Header closeButton>
          <Modal.Title>Remove partner entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="addPartnerABN"
            label="ABN"
            type="text"
            name="ABN"
            value={input?.ABN}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          {!!err && (
            <div className={`form-validation error`}>
              <div className="validation-txt">
                <p className="v-text error">{err}</p>
              </div>
            </div>
          )}

          <Button onClick={handleProcess}>Search</Button>
        </Modal.Body>
      </Modal>

      <Modal show={showCfmPanel && !!cfm} onHide={closeCfmPanel}>
        <Modal.Header closeButton>
          <Modal.Title>Remove partner entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          {!!cfm && (
            <>
              <div>
                <div>
                  <>ABN:</> <>{cfm?.ABN}</>
                </div>

                <div>
                  <>Phone:</> <>{cfm?.phone}</>
                </div>

                <div>
                  <>Name:</> <>{cfm?.name}</>
                </div>

                <div>
                  <>Email:</> <>{cfm?.email}</>
                </div>

                <div>
                  <>Partner program:</> <>{cfm?.isTypeCAP ? "CAP" : "NA"}</>
                </div>

                <div>
                  <>Partner type:</>{" "}
                  <>
                    {[
                      "isTypeReferrer",
                      "isTypeSupplyChain",
                      "isTypeMembership",
                      "isTypeConsultant",
                      "isTypeMSP",
                      "isTypeFranchise",
                      "isTypeVendor",
                    ]
                      .filter((t) => cfm[t])
                      .map((k) => {
                        return {
                          isTypeReferrer: "Referrer",
                          isTypeSupplyChain: "Supply Chain",
                          isTypeMembership: "Membership",
                          isTypeConsultant: "Consultant",
                          isTypeMSP: "MSP",
                          isTypeFranchise: "Franchise",
                          isTypeVendor: "Vendor",
                        }[k];
                      })
                      .join(", ")}
                  </>
                </div>

                <></>
              </div>

              <Button onClick={handleConfirm}>Remove</Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
