import { spinner } from "assets";
import { FormInput, Spinner } from "components";
import { ROUTE } from "variables";
import { useLoadingState } from "middleware";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services";

import "./mfa-confirm.scss";

const initialState = {
  code: "",
};

const initialValidation = {
  code: "NA",
};

const validationRules = ["code"];

export function MfaConfirm({ onSignin = () => {} }) {
  const [disabled, setDisabled] = useState(true);
  const { loading, setLoading } = useLoadingState();
  const [confirmed, setConfirmed] = useState(false);

  const [confirmState, setConfirmState] = useState(initialState);
  const [validation, setValidation] = useState(initialValidation);
  const [codeOpResult, setCodeOpResult] = useState("NA");
  const [successTxt, setSuccessTxt] = useState();
  const [errorTxt, setErrorTxt] = useState();

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    try {
      if (!state?.email || !state?.password) {
        navigate(ROUTE.ROOT);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  function handleInput(event) {
    setConfirmState({
      ...confirmState,
      [event.target.name]: event.target.value,
    });
  }

  const confirm = async () => {
    try {
      const { code } = confirmState;
      console.log(code);

      await AuthService.verifyMFA(
        state.email,
        state.password,
        code,
        () => navigate(ROUTE.ROOT),
        onSignin
      );
    } catch (error) {
      // console.log(error);
      setCodeOpResult("error");
      setErrorTxt("Your verification code is incorrect.");
    } finally {
      setLoading(false);
    }
  };

  const pSetValidation = (name, value) => {
    const validationResult = { ...validation, [name]: value };
    setValidation(validationResult);
    setDisabled(
      !validationRules.every((prop) => validationResult[prop] === "success")
    );
  };

  const validateSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const canSubmit = validationRules.every((prop) => !!confirmState[prop]);

    if (!canSubmit) {
      setDisabled(true);
    } else {
      confirm();
    }
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      {confirmed ? (
        <>
          <div className="auth-title">Your email is verified</div>
          <div className="subtitle">
            Your email has been verified successfully!
            <br />
            Access your account by logging in.
          </div>

          <Button
            className="btn btn-warning-gd"
            onClick={() => {
              window.location.href = ROUTE.LOGIN;
            }}
          >
            Login
          </Button>
        </>
      ) : (
        <>
          <div className="auth-title">Multi Factor Authentication</div>
          <div className="subtitle">
            Please enter the code generated in your Authenticator app to confirm
            your signin.
          </div>

          <Form>
            <div className={`form-validation ${codeOpResult}`}>
              <div className="validation-txt">
                {!!successTxt && <p className="v-text success">{successTxt}</p>}
                {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
              </div>
            </div>

            <FormInput
              controlId="confirmCode"
              label="Verification Code"
              type="code"
              name="code"
              onChange={handleInput}
              pValidation={validation.code}
              pSetValidation={(value) => pSetValidation("code", value)}
              required
            />
            <div className="mt-30">
              <Button
                className="register-btn btn btn-warning-gd"
                onClick={validateSubmit}
                disabled={disabled}
              >
                Verify
              </Button>
            </div>
          </Form>
        </>
      )}
    </>
  );
}
