import { makeGetFunction } from "api/utils";
import { listRequirements } from "graphql/queries";

export const getRequirementsByABN = (ABN) =>
  !!ABN
    ? makeGetFunction({
        name: "listRequirements",
        query: listRequirements,
        params: {
          filter: {
            ABN: {
              eq: ABN,
            },
          },
          limit: 10000,
        },
      })()
    : undefined;
