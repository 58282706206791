import { makeDeleteFunction } from "api/utils";
import { deleteRecommendationTool as deleteAPI } from "graphql/mutations";
import { getRecommendationTool } from "./queries";

export const deleteRecommendationTool = async (ABN) => {
  const questionaire = await getRecommendationTool(ABN);

  if (!!questionaire) {
    return makeDeleteFunction({
      name: "deleteRecommendationTool",
      query: deleteAPI,
      params: {
        input: {
          id: questionaire?.id,
        },
      },
    })();
  }
};
