import { filter_account } from "assets";
import { useState } from "react";

const FILTER_MAP = {
  CLAIM_ABN: "Claim ABN",
  PURCHASE_LEVEL: "Purchase Level",
  COMPLETE_CERTIFICATE: "Complete Certificate",
  START_CERTIFICATE: "Start Certificate",
  SUBMIT_ATTESTATION: "Submit Attestation",
  SEND_ATTESTATION: "Send Attestation",
  SIGN_ATTESTATION: "Sign Attestation",
};

const INITIAL_FILTER = {
  CLAIM_ABN: false,
  PURCHASE_LEVEL: false,
  COMPLETE_CERTIFICATE: false,
  START_CERTIFICATE: false,
  SUBMIT_ATTESTATION: false,
  SEND_ATTESTATION: false,
  SIGN_ATTESTATION: false,
};

export function RecentActivityItemFilter({ filterFn }) {
  const [activeType, setActiveType] = useState();

  const filter = (type) => {
    if (type === activeType) {
      setActiveType(null);
      filterFn(null);
    } else {
      setActiveType(type);
      filterFn(type);
    }
  };

  return (
    <>
      <div className="mt-30 content-admin">
        <div className="heading">
          <h2 className="content-admin-title">Filter by Activity Type</h2>
        </div>
        <div className="filter-checkbox">
          {Object.keys(FILTER_MAP).map((type) => (
            <>
              <button
                onClick={() => {
                  filter(type);
                }}
                className={`btn filter-btn ${activeType === type && "active"}`}
              >
                {FILTER_MAP[type]}
              </button>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
