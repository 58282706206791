import logoSvg from "./logo.svg";
import logoDarkSvg from "./images/logo-secondary.svg";
import authBannerSvg from "./images/auth-banner.svg";
import showPwdSvg from "./images/show-passwd.svg";
import corporateIconSvg from "./images/corporate-icon.svg";
import tagInfoIconSvg from "./images/tag-info-icon.svg";
import tagAlertIconSvg from "./images/tag-alert-icon.svg";
import noCertImageSvg from "./images/no-certification-image.svg";
import helpdeskIconSvg from "./images/helpdesk-icon.svg";
import searchIconSvg from "./images/search-icon.svg";
import successIconSvg from "./images/success-icon.svg";
import failIconSvg from "./images/fail-icon.svg";
import certificate1Svg from "./images/certificate-01.svg";
import certificate2Svg from "./images/certificate-02.svg";
import certificate3Svg from "./images/certificate-03.svg";
import flagSvg from "./images/flag.svg";
import difficultyLevel1Svg from "./images/difficulty-level-01.svg";
import difficultyLevel2Svg from "./images/difficulty-level-02.svg";
import difficultyLevel3Svg from "./images/difficulty-level-03.svg";
import difficultyLevel4Svg from "./images/difficulty-level-04.svg";
import difficultyLevel5Svg from "./images/difficulty-level-05.svg";
import checkIconSvg from "./images/check-icon.svg";
import selectArrowSvg from "./images/select-arrow.svg";
import certificationGuideSvg from "./images/certification-guide.jpg";
import avatarSvg from "./images/avatar.png";
import checkInProgressSvg from "./images/check-in-progress-icon.svg";
import googleIcon from "./images/google.svg";
import facebookIcon from "./images/facebook.svg";
import badgeL1 from "./images/badgeL1.png";
import badgeL2 from "./images/badgeL2.png";
import badgeL3 from "./images/badgeL3.png";
import badgeL4 from "./images/badgeL4.png";
import badgeL5 from "./images/badgeL5.png";
import Illustration404 from "./images/404-illustration.svg";
import ArrowForward from "./images/arrow_forward.svg";
import WBCover from "./images/workbook-cover.png";
import OfflineImg from "./images/offline.svg";
import spinnerJson from "./animations/json/spinner.json";
import filterList from "./images/filter_list.svg";
import addAccount from "./images/add-account.svg";
import deleteAccount from "./images/delete.svg";
import editAccount from "./images/edit-account.svg";
import sampleQrCode from "./images/SampleQR.png";

export const logo = logoSvg;
export const logoDark = logoDarkSvg;
export const authBanner = authBannerSvg;
export const showPasswd = showPwdSvg;
export const corporateIcon = corporateIconSvg;
export const tagInfoIcon = tagInfoIconSvg;
export const tagAlertIcon = tagAlertIconSvg;
export const noCertImage = noCertImageSvg;
export const helpdeskIcon = helpdeskIconSvg;
export const searchIcon = searchIconSvg;
export const successIcon = successIconSvg;
export const failIcon = failIconSvg;
export const certificate01 = certificate1Svg;
export const certificate02 = certificate2Svg;
export const certificate03 = certificate3Svg;
export const flag = flagSvg;
export const difficultyLevel1 = difficultyLevel1Svg;
export const difficultyLevel2 = difficultyLevel2Svg;
export const difficultyLevel3 = difficultyLevel3Svg;
export const difficultyLevel4 = difficultyLevel4Svg;
export const difficultyLevel5 = difficultyLevel5Svg;
export const checkIcon = checkIconSvg;
export const selectArrow = selectArrowSvg;
export const certificationGuide = certificationGuideSvg;
export const avatar = avatarSvg;
export const checkInProgressIcon = checkInProgressSvg;
export const google = googleIcon;
export const facebook = facebookIcon;
export const badgeLv1 = badgeL1;
export const badgeLv2 = badgeL2;
export const badgeLv3 = badgeL3;
export const badgeLv4 = badgeL4;
export const badgeLv5 = badgeL5;
export const Img404 = Illustration404;
export const ArrowFwd = ArrowForward;
export const WorkbookCover = WBCover;
export const Offline = OfflineImg;
export const spinner = spinnerJson;
export const filter_account = filterList;
export const add_account = addAccount;
export const delete_account = deleteAccount;
export const edit_account = editAccount;
export const SampleQR = sampleQrCode;