import { makeGetFunction } from "api/utils";
import {
  activityFeedsByDate as listAPI,
  activitiesByType,
} from "graphql/queries";

export const listActivities = async (input = {}) => {
  const { filter, limit, nextToken } = input;
  let recentActivities = await makeGetFunction({
    name: "activityFeedsByDate",
    query: listAPI,
    params: {
      filter: {
        ...filter,
      },
      self: "ActivityFeed",
      sortDirection: "DESC",
      limit: limit || 20,
      nextToken,
    },
  })();

  return recentActivities;
};

export const listActivitiesByType = async (type, input = {}) => {
  const { filter, limit, nextToken } = input;
  let recentActivities = await makeGetFunction({
    name: "activitiesByType",
    query: activitiesByType,
    params: {
      filter: {
        ...filter,
      },
      type,
      sortDirection: "DESC",
      limit: limit || 20,
      nextToken,
    },
  })();

  return recentActivities;
};
