import {
    createCertification as createAPI,
    deleteCertification as deleteAPI,
    updateCertification as updateAPI,
} from "graphql/mutations";
import {
    makeCreateFunction,
    makeDeleteFunction,
    makeUpdateFunction,
} from "../../utils";
  
  export const createCertification = (input) =>
    makeCreateFunction({
      name: "createCertification",
      query: createAPI,
      params: {
        input,
      },
    })();
  
  export const updateCertification = (input) =>
    makeUpdateFunction({
      name: "updateCertification",
      query: updateAPI,
      params: {
        input,
      },
    })();
  
  export const deleteCertification = (input) =>
    makeDeleteFunction({
      name: "deleteCertification",
      query: deleteAPI,
      params: {
        input,
      },
    })();
  