import { logoDark } from "assets";
import { ROUTE } from "variables";
import { Link } from "react-router-dom";

import "./auth-layout.scss";

export function AuthLayout({ metaDescription, children }) {
  return (
    <>
      {metaDescription}

      <div className="auth-super-container">
        <div className="auth-container">
          <div className="logo">
            <Link to={ROUTE.ROOT}>
              <img src={logoDark} alt="Logo" />
            </Link>
          </div>

          {children}
        </div>

        <div className="small-footer">
          <div>
            <p>
              © Copyright 2022 CyberCert Pty Ltd. All rights reserved. ABN 87 662 681 423
            </p>
          </div>
          <div className="sf-links">
            <Link to={ROUTE.PRIVACY_POLICY}>Privacy Policy</Link>
            {" | "}
            <Link to={ROUTE.TERMS}>Terms of use</Link>  
          </div>
        </div>
      </div>
    </>
  );
}
