import { TOTPSettings } from "./totp-settings";

export function AccountSettings() {
  return (
    <>
      <div className="content-admin">
        <div className="heading">
          <h2 className="block-title">Account Settings</h2>
        </div>

        <TOTPSettings />
      </div>
    </>
  );
}
