import { LayoutWrapper, RequireAuth, RequireNoAuth } from "components";
import { MetaDescription } from "components/meta-manager/MetaDescription";
import ScrollToTop from "components/scroll-to-top/scrollToTop";
import { AuthLayout } from "layouts";
import {
  ActivityFeedPage,
  CertificationsPage,
  Cookie,
  Login,
  Maintenance,
  MfaConfirm,
  PageNotFound,
  PrivacyPolicy,
  Terms,
  ViewCertification,
} from "pages";
import { AdminPage } from "pages/admin-page";
import { Route, Routes } from "react-router-dom";
import { ROUTE } from "variables";

import { MfaSetup } from "pages/auth-mfa/mfa-setup";
import "./routes.scss";
import { SettingsPage } from "pages/settings-page/settings-page";
import { AllSales, ViewSale } from "pages/sales";
import { AllReports } from "pages/reports";

export function Router() {
  return (
    <>
      <div className="routes-container">
        <ScrollToTop>
          <Routes>
            <Route
              exact
              path={ROUTE.ROOT}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    {/* <SupplyChainManagementPage /> */}
                    <AdminPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            {/* <Route
              exact
              path="/supply-chain/management"
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <SupplyChainManagementPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              exact
              path="/organisations"
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <OrganisationsPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              exact
              path="/primes"
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <PrimesPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              exact
              path="/partners"
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <PartnersPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            <Route
              exact
              path={ROUTE.CERTIFICATIONS}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <CertificationsPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            <Route
              path={ROUTE.VIEW_CERTIFICATION}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <ViewCertification />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            {/* <Route
              exact
              path="/suppliers"
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <SuppliersPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              exact
              path={ROUTE.ACCOUNTS}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AllAccounts />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              exact
              path={ROUTE.ADD_ACCOUNT}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AddAccount />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.VIEW_ACCOUNT}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <ViewAccount />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            <Route
              path={ROUTE.ACTIVITIES}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <ActivityFeedPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            <Route
              path={ROUTE.SETTINGS}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <SettingsPage />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            {/* <Route
              path={ROUTE.CAMPAIGNS}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AllCampaigns />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.ADD_CAMPAIGN}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AddCampaign />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.VIEW_CAMPAIGN}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <ViewCampaign />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.COMMISSIONS}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AllCommissions />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.ADD_COMMISSION}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AddCommission />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.VIEW_COMMISSION}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <ViewCommission />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            {/* <Route
              path={ROUTE.PAID_COMMISSION}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AllPaid />
                  </LayoutWrapper>
                </RequireAuth>
              }
            /> */}

            <Route
              path={ROUTE.SALES}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AllSales />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            <Route
              path={ROUTE.VIEW_SALE}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <ViewSale />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            <Route
              path={ROUTE.REPORTS}
              element={
                <RequireAuth>
                  <LayoutWrapper>
                    <AllReports />
                  </LayoutWrapper>
                </RequireAuth>
              }
            />

            <Route
              exact
              path={ROUTE.LOGIN}
              element={
                <RequireNoAuth>
                  <AuthLayout
                    metaDescription={
                      <MetaDescription
                        pageTitle="Login"
                        pageDescription="Login to your CyberCert account"
                      />
                    }
                  >
                    <Login />
                  </AuthLayout>
                </RequireNoAuth>
              }
            />

            <Route
              exact
              path={ROUTE.MFA_VERIFY}
              element={
                <AuthLayout
                  metaDescription={
                    <MetaDescription
                      pageTitle="Login"
                      pageDescription="Login to your CyberCert account"
                    />
                  }
                >
                  <MfaConfirm />
                </AuthLayout>
              }
            />

            <Route
              exact
              path={ROUTE.MFA_SETUP}
              element={
                <AuthLayout
                  metaDescription={
                    <MetaDescription
                      pageTitle="Login"
                      pageDescription="Login to your CyberCert account"
                    />
                  }
                >
                  <MfaSetup />
                </AuthLayout>
              }
            />

            <Route
              exact
              path={ROUTE.PRIVACY_POLICY}
              element={
                <LayoutWrapper>
                  <PrivacyPolicy />
                </LayoutWrapper>
              }
            />

            <Route
              exact
              path={ROUTE.TERMS}
              element={
                <LayoutWrapper>
                  <Terms />
                </LayoutWrapper>
              }
            />

            <Route
              exact
              path={ROUTE.COOKIE}
              element={
                <LayoutWrapper>
                  <Cookie />
                </LayoutWrapper>
              }
            />

            <Route path={ROUTE.MAINTENANCE} element={<Maintenance />} />

            <Route
              path={ROUTE.PAGE_NOT_FOUND}
              element={
                <LayoutWrapper>
                  <PageNotFound />
                </LayoutWrapper>
              }
            />
          </Routes>
        </ScrollToTop>
      </div>
    </>
  );
}
