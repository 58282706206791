import { getOrganisation, sendSignRequestEmail } from "api";
import { spinner } from "assets";
import { FormInput, Spinner } from "components";
import { LinkArrowSvg } from "components/svgs/link-arrow";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { sanitizeInputWithSpace } from "services";

export function SendSignRequestEmail() {
  const [showPanel, setShowPanel] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [sendOk, setSendOk] = useState("na");
  const [email, setEmail] = useState();
  const [abnCheckResult, setABNCheckResult] = useState();
  const [abnInput, setAbnInput] = useState();

  const handleClose = () => {
    setShowPanel(false);
    setEmail(null);
    setABNCheckResult(null);
    setSendOk("na");
  };
  const handleShow = () => {
    setShowPanel(true);
    setABNCheckResult(null);
    setSendOk("na");
  };

  const send = async () => {
    setInternalLoading(true);

    if (canResendSignRequest())
      await sendSignRequestEmail({
        progress: abnCheckResult,
      });

    handleClose();

    setInternalLoading(false);
  };

  const check = async () => {
    setInternalLoading(true);

    const org = await getOrganisation(sanitizeInputWithSpace(abnInput));

    const progress = org?.certificationProgress;

    if (!!progress) {
      setABNCheckResult(progress);
    } else {
      setABNCheckResult(null);
    }

    setInternalLoading(false);
  };

  const canResendSignRequest = () => {
    return (
      !!abnCheckResult &&
      abnCheckResult?.current === 4 &&
      !!abnCheckResult?.envelopeId
    );
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={handleShow}>
        <LinkArrowSvg />
        Send sign request email
      </Button>

      <Modal show={showPanel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send attestation sign request</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {internalLoading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="ABN"
            label="ABN"
            type="search"
            name="ABN"
            value={email}
            onChange={(event) => setAbnInput(event.target.value)}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <div>
            <Button className="btn btn-warning-gd" onClick={check}>
              Check
            </Button>
          </div>

          {!!abnCheckResult && (
            <>
              <div>
                <h5>Current certification progress:</h5>
                <div>
                  Certification Level <strong>{abnCheckResult?.level}</strong>
                </div>
                <div>
                  Status <strong>{abnCheckResult?.status}</strong>
                </div>
              </div>

              {abnCheckResult?.current !== 4 && (
                <>
                  <div>User must complete step 3 form first.</div>
                </>
              )}

              {!abnCheckResult?.envelopeId && (
                <>
                  <div>No record of submitted attestation.</div>
                </>
              )}
            </>
          )}

          <div className={`form-validation ${sendOk}`}>
            <div className="validation-txt">
              <p className="v-text success">Request success.</p>
              <p className="v-text error">Request failed.</p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={send}
            disabled={!canResendSignRequest()}
          >
            Request
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
