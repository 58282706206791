import {
  deleteSales,
  getSupplyChainEntityByABN,
  searchSalesFromSupplier,
} from "api";
import { delete_account, filter_account, showPasswd, spinner } from "assets";
import { AdminSearch, NavBreadcrumbList, Spinner } from "components";
import { ConfirmPopup } from "components/confirm";
import { SearchResultsWithPagination } from "components/pagination";
import { ROUTE } from "variables";
import { COMMISSION_ITEMS } from "variables/commission";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { parseDate } from "services";

import "./index.scss";

export function AllSales() {
  const { loading, setLoading } = useLoadingState();
  const [showFilter, setShowFilter] = useState(false);
  const [searchRef, setSearchRef] = useState();
  const searchResultsRef = useRef();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const selectRef = useRef();
  const deleteRef = useRef();
  const navigate = useNavigate();

  const typeFilterRef = {
    PURCHASE: useRef(),
    RENEW: useRef(),
    UPGRADE: useRef(),
  };
  const levelFilterRef = {
    1: useRef(),
    2: useRef(),
    3: useRef(),
    4: useRef(),
    5: useRef(),
  };

  useEffect(() => {
    if (!!params.abn) {
      performSearch(params.abn);
    } else {
      performSearch("");
    }
  }, []);

  const performSearch = async (value = "", filter) => {
    setLoading(true);

    let sales = await searchSalesFromSupplier(value);

    if (!!filter) {
      sales = sales
        .filter((sale) => filter.levels.includes(COMMISSION_ITEMS[sale.item]))
        .filter(
          (sale) =>
            filter.types.length === 0 || filter.types.includes(sale.category)
        );

      // console.log(filter);
      // console.log(sales);
    }

    sales = await Promise.all(
      sales.map(async (sale) => ({
        ...sale,
        supplierName: (await getSupplyChainEntityByABN(sale.supplier))?.name,
      }))
    );

    searchResultsRef?.current?.setPages(sales);

    setLoading(false);
  };

  const getSelectInputs = () => {
    return Array.from(selectRef.current.children).map(
      (child) => child.children[0].children[0].children[0]
    );
  };

  const selectAll = (e) => {
    const value = e.target.checked;
    const selectInputs = getSelectInputs();
    selectInputs.forEach((input) => {
      input.checked = value;
    });
  };

  const deleteSelectedItems = async () => {
    setLoading(true);

    const selectedItems = getSelectInputs()
      .filter((item) => item.checked === true)
      .map((item) => item.getAttribute("id-key"));

    for (let i = 0; i < selectedItems.length; ++i) {
      await deleteSales({ id: selectedItems[i] });
    }

    window.location.reload();

    setLoading(false);
  };

  const deleteSingleItem = async (id) => {
    getSelectInputs().forEach((item) => {
      if (item.getAttribute("id-key") === id) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    deleteRef.current?.show();
  };

  const filterSales = () => {
    let types = Object.keys(typeFilterRef).filter(
      (k) => typeFilterRef[k].current.checked
    );
    let levels = Object.keys(levelFilterRef)
      .filter((k) => levelFilterRef[k].current.checked)
      .map((k) => parseInt(k));

    if (levels.length === 0) {
      levels = [1, 2, 3, 4, 5];
    }

    performSearch(searchRef, {
      types,
      levels,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.click();
    }
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.SALES, name: "Sales" },
                    ]}
                  />
                  <h1 className="title">Sales</h1>
                  <div className="mt-30"></div>
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>

            <AdminSearch
              name="partner"
              searchBy="ABN"
              searchFn={performSearch}
              setParentSearchRef={setSearchRef}
            />
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <div className="account-actions mt-30">
              <button
                className="filter"
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filter_account} alt="Filter account" />
                Filter
              </button>
              <div className="manage">
                <div
                  className="action"
                  tabIndex={0}
                  onClick={() => {
                    deleteRef.current?.show();
                  }}
                  onKeyDown={handleKeyDown}
                >
                  Delete
                  <img src={delete_account} alt="Delete sales" />
                </div>
              </div>
            </div>

            {showFilter ? (
              <div className="mt-30 content-admin">
                <div className="heading">
                  <h2 className="content-admin-title">Filter by Type</h2>
                </div>
                <div className="filter-checkbox">
                  <label>
                    <div className="fake-input">
                      <input
                        type="checkbox"
                        name=""
                        ref={typeFilterRef.PURCHASE}
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">Purchases</span>
                    </div>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        type="checkbox"
                        name=""
                        ref={typeFilterRef.RENEW}
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">Renewals</span>
                    </div>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        type="checkbox"
                        name=""
                        ref={typeFilterRef.UPGRADE}
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">Upgrades</span>
                    </div>
                  </label>
                </div>
                <div className="heading">
                  <h2 className="content-admin-title">Filter by Level</h2>
                </div>
                <div className="filter-checkbox">
                  {[1, 2, 3, 4, 5].map((level) => (
                    <label>
                      <div className="fake-input">
                        <input
                          type="checkbox"
                          name=""
                          ref={levelFilterRef[level]}
                          onKeyDown={handleKeyDown}
                        />
                        <span className="fake-checkbox">Level {level}</span>
                      </div>
                    </label>
                  ))}
                </div>

                <div className="filter-controls">
                  <Button className="btn btn-warning-gd" onClick={filterSales}>
                    Filter
                  </Button>
                  <Button
                    className="btn clear-btn"
                    onClick={async () => {
                      const filterRefs = [typeFilterRef, levelFilterRef];

                      filterRefs.forEach((fRef) => {
                        Object.keys(fRef).forEach((k) => {
                          if (!!fRef[k].current) {
                            fRef[k].current.checked = false;
                          }
                        });
                      });

                      filterSales();
                    }}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            ) : null}

            <SearchResultsWithPagination
              ref={searchResultsRef}
              selectRef={selectRef}
              tableHeader={
                <thead>
                  <tr>
                    <td className="sticky-col select-col">
                      <label>
                        <input
                          className="fake-input"
                          type="checkbox"
                          name=""
                          onClick={selectAll}
                          onKeyDown={handleKeyDown}
                        />
                      </label>
                    </td>
                    <td className="sticky-col action-col"></td>
                    <td>ID</td>
                    <td>ABN</td>
                    <td>Organisation Name</td>
                    <td>Type</td>
                    <td>Level</td>
                    <td>Discount rate</td>
                    <td>Amount paid</td>
                    <td>Date paid</td>
                  </tr>
                </thead>
              }
              tableRow={(item) => (
                <tr>
                  <td className="sticky-col select-col">
                    <label>
                      <div className="fake-input">
                        <input
                          id-key={item.id}
                          type="checkbox"
                          name=""
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </label>
                  </td>
                  <td className="sticky-col action-col">
                    <div className="edit-delete">
                      <img
                        src={showPasswd}
                        alt="View account"
                        onClick={() => {
                          navigate(
                            `${ROUTE.VIEW_SALE.replace(":id", item?.id)}`
                          );
                        }}
                      />
                      |
                      <img
                        src={delete_account}
                        alt="Delete account"
                        onClick={() => {
                          deleteSingleItem(item.id);
                        }}
                      />
                    </div>
                  </td>
                  <td>{item.id}</td>
                  <td>{item.supplier}</td>
                  <td>{item.supplierName || <>N/A</>}</td>
                  <td className="capitalize">{item.category}</td>
                  <td className="capitalize">{item.item}</td>
                  <td>{item.discountRate}</td>
                  <td>{item.payment}</td>
                  <td>{parseDate(item.createdAt)}</td>
                </tr>
              )}
            />
          </div>
        </div>
      </div>

      <ConfirmPopup
        ref={deleteRef}
        title="Delete sales"
        body="Confirm deleting selected sales"
        confirmTxt="Delete"
        confirmFn={deleteSelectedItems}
      />
    </>
  );
}
