import {
  makeCreateFunction,
  makeDeleteFunction,
  makeUpdateFunction,
} from "api/utils";
import {
  createPartner as createAPI,
  deletePartner as deleteAPI,
  updatePartner as updateAPI,
} from "graphql/mutations";

export const createPartner = (input) =>
  makeCreateFunction({
    name: "createPartner",
    query: createAPI,
    params: {
      input,
    },
  })();

export const updatePartner = (input) =>
  makeUpdateFunction({
    name: "updatePartner",
    query: updateAPI,
    params: {
      input,
    },
  })();

export const deletePartner = (input) =>
  makeDeleteFunction({
    name: "deletePartner",
    query: deleteAPI,
    params: {
      input,
    },
  })();
