import "./index.scss";
import { Player } from "@lottiefiles/react-lottie-player";

export function Spinner({ jsonTemplate, color = "", size = "" }) {
  return (
    <>
      {!!jsonTemplate ? (
        <div className="json-spinner">
          <Player autoplay loop src={jsonTemplate} />
        </div>
      ) : (
        <div className={`lds-ring ${color} ${size}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </>
  );
}
