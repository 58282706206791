import { API } from "aws-amplify";

export const generateCertification = async ({ ABN, level }) => {
  const myInit = {
    body: {
      ABN,
      level,
    },
  };
  const result = await API.post("apiAdmin", "/certification/add", myInit);
    console.log(result);
  return result;
};
