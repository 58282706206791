import { logoDark } from "assets";
import { ROUTE } from "variables";
import { Link } from "react-router-dom";

import "./index.scss";

export function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-info">
            <div className="footer-logo">
              <Link to={ROUTE.ROOT}>
                <img src={logoDark} alt="Logo" />
              </Link>
              <div className="copyright-block">
                <p>
                  CyberCert Pty Ltd | ABN 87 662 681 423
                </p>
                <p>
                  © Copyright 2022. All rights reserved.
                </p>
              </div>
            </div>
          </div>
          <div className="footer-contacts">
            <div className="footer-contact-item">
              <h5>Legal</h5>
              <ul>
                <li>
                  <Link to={ROUTE.PRIVACY_POLICY}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={ROUTE.TERMS}>Terms of Use</Link>
                </li>
                <li>
                  <Link to={ROUTE.COOKIE}>Cookie Notice</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
