import { listUsers } from "api";
import { spinner } from "assets";
import { Spinner } from "components";
import { LinkArrowSvg } from "components/svgs/link-arrow";
import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthService } from "services";

export function RemoveUserAccounts() {
  const [showPanel, setShowPanel] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [search, setSearch] = useState();
  const [searchRef, setSearchRef] = useState();
  const [searchResults, setSearchResults] = useState();
  const [searchErr, setSearchErr] = useState("na");
  const [internalLoading, setInternalLoading] = useState(false);
  const [deleteOk, setDeleteOk] = useState("na");

  const searchResultsRef = useRef();

  const searchAccounts = async () => {
    setInternalLoading(true);

    const users = await listUsers();

    if (!!users && users.length > 0) {
      setSearchResults(users);
      setSearchErr("success");
    } else {
      setSearchResults(null);
      setSearchErr("error");
    }

    setInternalLoading(false);
    setDeleteOk("na");
  };

  const handleClose = () => {
    setShowPanel(false);
    setSearchResults(null);
    setDeleteOk("na");
  };
  const handleShow = () => {
    setShowPanel(true);
    setDeleteOk("na");
    searchAccounts();
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setSearchResults(null);
    setDeleteOk("na");
  };
  const handleShowConfirm = () => {
    const accountsToRemove = getSelectedAccounts();
    if (accountsToRemove.length > 0) {
      setShowConfirm(true);
      setDeleteOk("na");
    }
  };

  const handleDelete = async () => {
    setDeleteOk("na");
    setShowConfirm(false);
    setInternalLoading(true);

    try {
      const accountsToRemove = getSelectedAccounts();

      const searchesToRemove = searchResults.filter((res) =>
        accountsToRemove.includes(res.id)
      );

      let ok;
      await Promise.all(
        searchesToRemove.map(async (toRemove) => {
          ok = await AuthService.adminClearUser(toRemove);
        })
      );
      setDeleteOk(ok);
    } catch (err) {
      console.log(err);
      setDeleteOk("error");
    }

    setSearchResults(null);
    setInternalLoading(false);
  };

  const selectAccountToClear = (idx) => {
    Array.from(searchResultsRef?.current?.children).forEach((child, i) => {
      if (idx === i) {
        if (child.className.includes("selected")) {
          child.className = "account-search-res selectable";
        } else {
          child.className = "account-search-res selectable selected";
        }
      }
    });
  };

  const getSelectedAccounts = () => {
    return Array.from(searchResultsRef?.current?.children)
      .filter((child) => child.className.includes("selected"))
      .map((child) => child.getAttribute("id-key"));
  };

  return (
    <div>
      {/* <Button variant="btn btn-admin-settings" onClick={handleShow}>
        <LinkArrowSvg />
        Clear all accounts
      </Button> */}

      <Modal show={showPanel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Clear User Accounts</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {internalLoading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          {!!searchResults && (
            <div className="searchResults" ref={searchResultsRef}>
              {searchResults.map((searchRes, i) => (
                <div
                  className="account-search-res selectable selected"
                  onClick={() => selectAccountToClear(i)}
                  id-key={searchRes.id}
                >
                  <p>
                    <b>Provider:</b> {searchRes?.provider}
                  </p>
                  <p>
                    <b>Email:</b> {searchRes?.email}
                  </p>
                  <p className="capitalize">
                    <b>Name:</b> {searchRes?.firstName} {searchRes?.lastName}
                  </p>
                  <p>
                    <b>Organisation:</b> {searchRes?.ABN}
                  </p>
                  <p className="capitalize">
                    <b>Organisation Name:</b> {searchRes?.organisation?.name}
                  </p>
                  <p>
                    <b>Certification Level:</b>{" "}
                    {searchRes?.organisation?.certificationLevel}
                  </p>

                  <hr />
                </div>
              ))}

              <p>Select the account(s) you want to delete.</p>
            </div>
          )}

          <div className={`form-validation ${deleteOk}`}>
            <div className="validation-txt">
              <p className="v-text success">Delete success.</p>
              <p className="v-text error">Delete failed.</p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleShowConfirm}
            disabled={!searchResults}
          >
            Delete user
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Clear User Accounts</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Confirm deleting these users</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={handleDelete}
            disabled={!searchResults}
          >
            Delete user
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
