export const ROUTE = {
  ROOT: "/",
  LOGIN: "/login",
  MFA_VERIFY: "/mfa/verify",
  MFA_SETUP: "/mfa/setup",
  REGISTER: "/register",
  REGISTER_CONFIRM: "/register/confirm",
  PROFILE: "/profile",
  PROFILE_SETUP: "/profile/setup",
  DASHBOARD: "/dashboard",
  CERTIFICATION_MANAGER: "/certification",
  ACCOUNT: "/account",
  TERMS: "/terms",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE: "/cookie",
  PAGE_NOT_FOUND: "*",
  RESET_PASSWORD: "/reset-password",
  CLAIM: "/claim",
  CERTIFICATE_VIEW: "/certificate/view",
  ACCOUNTS: "/accounts",
  ADD_ACCOUNT: "/accounts/add",
  VIEW_ACCOUNT: "/accounts/:id",
  SETTINGS: "/settings",
  CERTIFICATIONS: "/certifications",
  VIEW_CERTIFICATION: "/certifications/:id",
  CAMPAIGNS: "/campaigns",
  ADD_CAMPAIGN: "/campaigns/add",
  VIEW_CAMPAIGN: "/campaigns/:id",
  COMMISSIONS: "/commissions",
  ADD_COMMISSION: "/commissions/add",
  VIEW_COMMISSION: "/commissions/:id",
  PAID_COMMISSION: "/commissions/paid",
  SALES: "/sales",
  VIEW_SALE: "/sales/:id",
  REPORTS: "/reports",
  MAINTENANCE: "/maintenance",
  ACTIVITIES: "/activities",
};

export const CERTIFICATION_MANAGER = {
  ATTESTATION_DEED: {
    ROLES: ["Business Owner", "Director"],
    TITLE: ["Mr", "Mrs", "Ms", "Dr"],
  },
};

export const CERTIFICATION_STEPS_DEFAULT = 7;

export const CERTIFICATION_STEPS = {
  1: 5,
  2: 5,
  3: 5,
  4: 6,
  5: 6,
};

export const CERTIFICATION_STEP_TITLES = {
  1: "Download Requirements",
  2: "Complete Attestation",
  3: "Create Attestation Letter",
  4: "Sign Attestation Letter",
  5: "Request Audit Review",
  6: "Download your Certificate",
  7: "Share your Certificate",
};

export const AUDIT_LEVEL = 4;

export const ATTESTATION_STATUS = {
  completed: "COMPLETED",
  na: "NA",
};

export const CERTIFICATION_PROGRESS_STATUS = {
  in_progress: "IN_PROGRESS",
  completed: "COMPLETED",
  failed: "FAILED",
};

export const PRICING = {
  1: 95,
  2: 195,
  3: 395,
  4: 1995,
  5: 2995,
};

export const CERTIFICATION_INFO = {
  1: {
    marketingName: "Starter",
    description:
      "An entry level certification for businesses that have the basic cyber security  essentials in place.",
    validFor: 12, // months
    assuranceLevel: "Self Attested",
  },
  2: {
    marketingName: "Advanced",
    description:
      "An advanced level certification for businesses that are building cyber maturity and have good cyber hygiene practices in place.",
    validFor: 12, // months
    assuranceLevel: "Self Attested",
  },
  3: {
    marketingName: "Professional",
    description:
      "A professional level certification for businesses that have a good cyber risk management program in place.",
    validFor: 12, // months
    assuranceLevel: "Self Attested",
  },
  4: {
    marketingName: "High Performer",
    description:
      "A top level certification for high performing businesses that have high cyber maturity built on best-practice cyber risk management.",
    validFor: 24, // months
    assuranceLevel: "Verified",
  },
  5: {
    marketingName: "Champion",
    description:
      "Our top level certification for businesses that have the highest level of cyber maturity across all 5 areas. True champions!",
    validFor: 24, // months
    assuranceLevel: "Verified",
  },
};
