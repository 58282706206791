const { API } = require("aws-amplify");

export const addNewUserToOrganisation = async ({
  userEmail,
  userPhone,
  userFullName,
  ABN,
}) => {
  let result;

  try {
    const myInit = {
      body: {
        userEmail,
        userPhone,
        userFullName,
        ABN,
      },
    };
    result = await API.post("apiAdmin", "/user-invite/add", myInit);
  } catch (e) {
    console.log(e);
  }
  //   console.log(result);
  return result;
};
