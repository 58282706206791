import { API } from "aws-amplify";
export const passCertificationAudit = ({ id }) => {
  const myInit = {
    body: {
      id,
    },
  };

  return API.post("certification", `/passAudit`, myInit);
};
