export const parseDate = (d) => {
  if (!d) {
    return "N/A";
  }
  const date = new Date(d);
  return `${date.getDate()} ${date.toLocaleString("default", {
    month: "short",
  })} ${date.getFullYear()}`;
};

export const parseDateTime = (d) => {
  const aestTime = new Date(d).toLocaleString("en-US", {
    timeZone: "Australia/Brisbane",
  });
  const aestDate = new Date(aestTime);
  return `${pad0left(aestDate.getHours())}:${pad0left(
    aestDate.getMinutes()
  )}, ${aestDate.getDate()} ${aestDate.toLocaleString("default", {
    month: "short",
  })} ${aestDate.getFullYear()}`;
};

export const pad0left = (num, size = 2) => {
  var s = "0".repeat(size) + num;
  return s.substring(s.length - size);
};

export const capitalize = (str) => {
  return !!str ? capitalizeBy(capitalizeBy(str, " "), ".") : str;
};

const capitalizeBy = (str, delim) => {
  var splitStr = str.split(delim);
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(delim);
};

export const sanitizeInputWithSpace = (s) => {
  return s.replace(/ /g, "");
};
