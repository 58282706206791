import {
    makeCreateFunction,
    makeDeleteFunction,
    makeUpdateFunction,
} from "api/utils";
import {
    createUser as createAPI,
    deleteUser as deleteAPI,
    updateUser as updateAPI,
} from "graphql/mutations";

export const createUser = (input) =>
  makeCreateFunction({
    name: "createUser",
    query: createAPI,
    params: {
      input,
    },
  })();

export const updateUser = (input) =>
  makeUpdateFunction({
    name: "updateUser",
    query: updateAPI,
    params: {
      input,
    },
  })();

export const deleteUser = (input) =>
  makeDeleteFunction({
    name: "deleteUser",
    query: deleteAPI,
    params: {
      input,
    },
  })();
