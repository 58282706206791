import { API } from "aws-amplify";

export const addNewReferral = async ({
  firstName,
  lastName,
  email,
  ABN,
  linkToReferrer,
  certBundle,
}) => {
  let result;
  try {
    const myInit = {
      body: {
        firstName,
        lastName,
        email,
        partnerABN: ABN,
        linkToReferrer,
        certBundle,
      },
    };
    result = await API.post("apiAdmin", "/partner/referrer/add", myInit);
  } catch (e) {
    console.log(e);
  }
  return result;
};
