import { spinner } from "assets";
import { Spinner } from "components";
import { ROUTE } from "variables";
import { TOTPSettings } from "for-admin/totp-settings";
import { useLoadingState } from "middleware";

export function MfaSetup({ onSignin = () => {} }) {
  const { loading } = useLoadingState();

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <>
        <div className="auth-title">Multi Factor Authentication</div>
        <div className="subtitle">MFA is required.</div>

        <TOTPSettings
          onSuccess={() => {
            window.location.href = ROUTE.ROOT;
          }}
        />
      </>
    </>
  );
}
