import {
  deleteActivity as deleteActivityItem,
  listActivities,
  listActivitiesByType,
} from "api";
import { spinner } from "assets";
import {
  NavBreadcrumbList,
  RecentActivityItem,
  RecentActivityItemFilter,
  Spinner,
} from "components";
import { RecentActivityItemDeleteModal } from "components/recent-activity-item/delete-modal";
import { ROUTE } from "variables";
import { useLoadingState } from "middleware";
import { useEffect, useRef, useState } from "react";

export function ActivityFeedPage() {
  const { loading, setLoading } = useLoadingState();
  const [prevNextTokens, setPrevNextTokens] = useState(null);
  const [thisToken, setThisToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [activities, setActivities] = useState();

  const deleteRef = useRef();

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    setThisToken(null);
    filter(null);
  };

  const fetchActivities = async (nToken = undefined, filterBy_) => {
    setLoading(true);

    setFilterBy(filterBy_);

    let recentActivities;

    if (!filterBy_) {
      recentActivities = await listActivities({
        limit: 20,
        nextToken: nToken !== undefined ? nToken : nextToken,
      });
    } else {
      recentActivities = await listActivitiesByType(filterBy_, {
        limit: 20,
        nextToken: nToken !== undefined ? nToken : nextToken,
      });
    }

    setNextToken(recentActivities?.nextToken);
    setActivities(recentActivities);

    setLoading(false);
  };

  const prev = async () => {
    const prevTokens = prevNextTokens;
    const prevToken = prevTokens.pop();
    setPrevNextTokens(prevTokens);
    setThisToken(prevToken);
    fetchActivities(prevToken, filterBy);
  };

  const next = async () => {
    const prevTokens = prevNextTokens || [];
    prevTokens.push(thisToken);
    setThisToken(nextToken);
    setPrevNextTokens(prevTokens);
    fetchActivities(undefined, filterBy);
  };

  const deleteActivity = async (item) => {
    if (!!item?.id) {
      await deleteActivityItem(item.id);
      setup();
    }
  };

  const filter = async (filterBy_) => {
    if (!filterBy_) {
      return fetchActivities(null, null);
    }
    // reset pagination
    setPrevNextTokens(null);
    setThisToken(null);
    setNextToken(null);
    return fetchActivities(null, filterBy_);
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.ACTIVITIES, name: "Activity Feed" },
                    ]}
                  />

                  <h1 className="title">Activity Feed</h1>
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <RecentActivityItemFilter filterFn={filter} />

            <div className="admin-section-title recent-activity">
              <div className="heading">
                <p className="activity-heading"></p>
              </div>

              {!!activities && activities.items.length > 0 && (
                <>
                  {activities.items.map((item) => (
                    <RecentActivityItem
                      item={item}
                      deleteFn={() => deleteRef?.current?.show(item)}
                    />
                  ))}
                </>
              )}

              <div className="pagination indication">
                {!!prevNextTokens && prevNextTokens.length > 0 && (
                  <div className="indicator previous" onClick={prev}>
                    Previous
                  </div>
                )}
                {!!nextToken && (
                  <div className="indicator next" onClick={next}>
                    Next
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <RecentActivityItemDeleteModal
        ref={deleteRef}
        deleteFn={deleteActivity}
      />
    </>
  );
}
