import { useState } from "react";

export function Accordion(props) {

    const [show, setShow] = useState(false);

    return (
        <>
            <a className="opener" onClick={() => show ? setShow(false) : setShow(true)}>{props.title}</a>
            <div className={show ? 'slide show' : 'slide'}>
                {props.content}
            </div>
        </>
    );
}