import {
    createRequirement as createAPI,
    deleteRequirement as deleteAPI,
    updateRequirement as updateAPI,
  } from "graphql/mutations";
  import {
    makeCreateFunction,
    makeDeleteFunction,
    makeUpdateFunction,
  } from "../../utils";
  
  export const createRequirement = (input) =>
    makeCreateFunction({
      name: "createRequirement",
      query: createAPI,
      params: {
        input,
      },
    })();
  
  export const updateRequirement = (input) =>
    makeUpdateFunction({
      name: "updateRequirement",
      query: updateAPI,
      params: {
        input,
      },
    })();
  
  export const deleteRequirement = (input) =>
    makeDeleteFunction({
      name: "deleteRequirement",
      query: deleteAPI,
      params: {
        input,
      },
    })();
  