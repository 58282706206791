import { Offline, logo } from "assets";
import { MetaDescription } from "components/meta-manager/MetaDescription";
import { ROUTE } from "variables";
import { Link } from "react-router-dom";
import "./index.scss";

export function Maintenance() {
    return (
        <>
            <MetaDescription pageTitle="Site under Maintenance" pageDescription="CyberCert maintenance ongoing. Sorry for the inconvenience." />
            <div className="auth-super-container">
                <div className="auth-container maintenance">
                    <img src={Offline} alt="Site under Maintenance" />
                    <h2 className="heading2">We're tweaking a few things</h2>
                    <p>
                        The CyberCert platform is currently offline for maintenance. <br />
                        We apologise for any inconvenience. <br />
                        We will be back online soon.
                    </p>
                    <img src={logo} className="logo" alt="CyberCert Logo" />
                </div>
                <div className="small-footer">
                    <div>
                        <p>
                            © Copyright 2022 CyberCert Pty Ltd. All rights reserved. ABN 87 662 681 423
                        </p>
                    </div>
                    <div className="sf-links">
                        <Link to={ROUTE.PRIVACY_POLICY}>Privacy Policy</Link>
                        {" | "}
                        <Link to={ROUTE.TERMS}>Terms of use</Link>
                    </div>
                </div>
            </div>
        </>
    );
}