import { spinner } from "assets";
import { FormInput, Spinner } from "components";
import { LinkArrowSvg } from "components/svgs/link-arrow";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import {
  getCertificationProgress,
  getUserByABN,
  sendCompletedCertEmail,
} from "api";
import { parseDate, sanitizeInputWithSpace } from "services";

export function SendCertCompletedEmail() {
  const [showPanel, setShowPanel] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [sendOk, setSendOk] = useState("na");
  const [email, setEmail] = useState();
  const [abnCheckResult, setABNCheckResult] = useState();
  const [abnInput, setAbnInput] = useState();
  const [sendDetails, setSendDetails] = useState({});

  const handleClose = () => {
    setShowPanel(false);
    setEmail(null);
    setABNCheckResult(null);
    setSendOk("na");
  };
  const handleShow = () => {
    setShowPanel(true);
    setABNCheckResult(null);
    setSendOk("na");
  };

  const send = async () => {
    setInternalLoading(true);

    if (canSendCertCompleted() && !!sendDetails) {
      await sendCompletedCertEmail(sendDetails);
    }

    handleClose();

    setInternalLoading(false);
  };

  const check = async () => {
    setInternalLoading(true);

    const user = await getUserByABN(sanitizeInputWithSpace(abnInput));

    if (!!user) {
      const certificationProgress = await getCertificationProgress(
        user?.organisation?.certificationProgressId
      );
      setABNCheckResult({
        ...user,
        certificationProgress,
      });
      setSendDetails({
        to: user?.email,
        name: user?.firstName,
        id: certificationProgress?.id,
        level: certificationProgress?.level,
        completedDate: parseDate(certificationProgress?.completedDate),
        expiredDate: parseDate(certificationProgress?.expiredDate),
      });
    } else {
      setABNCheckResult(null);
      setSendDetails(null);
    }

    setInternalLoading(false);
  };

  const canSendCertCompleted = () => {
    return (
      !!abnCheckResult &&
      abnCheckResult?.certificationProgress?.status === "COMPLETED"
    );
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={handleShow}>
        <LinkArrowSvg />
        Send cert completed email
      </Button>

      <Modal show={showPanel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send certification completed email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {internalLoading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="ABN"
            label="ABN"
            type="search"
            name="ABN"
            value={email}
            onChange={(event) => setAbnInput(event.target.value)}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <div>
            <Button className="btn btn-warning-gd" onClick={check}>
              Check
            </Button>
          </div>

          {!!abnCheckResult?.certificationProgress && (
            <>
              <div>
                <h5>Current certification progress:</h5>
                <div>
                  User <strong>{abnCheckResult?.email}</strong>
                </div>
                <div>
                  Certification Level{" "}
                  <strong>
                    {abnCheckResult?.certificationProgress?.level}
                  </strong>
                </div>
                <div>
                  Status{" "}
                  <strong>
                    {abnCheckResult?.certificationProgress?.status}
                  </strong>
                </div>
              </div>

              {!canSendCertCompleted() && (
                <>
                  <div>User must complete current certification first.</div>
                </>
              )}

              <div>
                <h5>Send email details:</h5>

                <FormInput
                  controlId="email"
                  label="Send to"
                  type="text"
                  name="email"
                  value={abnCheckResult?.email}
                  onChange={(event) =>
                    setSendDetails({
                      ...sendDetails,
                      to: event.target.value,
                    })
                  }
                  errorTxt={""}
                  pValidation={null}
                  pSetValidation={() => {}}
                  required
                />

                <FormInput
                  controlId="name"
                  label="Recipient's name"
                  type="text"
                  name="name"
                  value={abnCheckResult?.firstName}
                  onChange={(event) =>
                    setSendDetails({
                      ...sendDetails,
                      name: event.target.value,
                    })
                  }
                  errorTxt={""}
                  pValidation={null}
                  pSetValidation={() => {}}
                  required
                />
              </div>
            </>
          )}

          <div className={`form-validation ${sendOk}`}>
            <div className="validation-txt">
              <p className="v-text success">Send success.</p>
              <p className="v-text error">Send failed.</p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={send}
            disabled={!canSendCertCompleted() || !sendDetails}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
