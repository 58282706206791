import { API } from "aws-amplify";

export const getCertRequirements = async (level) => {
  const myInit = {
    queryStringParameters: {
      level,
    },
  };

  const result = await API.get(
    "certification",
    `/getRequirements?level=${level}`,
    myInit
  );
  return result;
};
