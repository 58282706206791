import { NavBreadcrumbList } from "components";
import { MetaDescription } from "components/meta-manager/MetaDescription";
import { ROUTE } from "variables";
import "../legal.scss";

export function Cookie() {
  return (
    <>
      <MetaDescription
        pageTitle="Cookie Notice"
        pageDescription="Please read the Cookie Notice set by CyberCert"
      />
      <div className="main-dashboard-container">
        <div className="dashboard-header">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Dashboard" },
                      {
                        to: ROUTE.COOKIE,
                        name: "Cookie Notice",
                      },
                    ]}
                  />

                  <div className="heading">
                    <h1 className="title">Cookie Notice</h1>
                  </div>

                  <div className="text">
                    This policy was last updated on July 1 2022
                  </div>
                </div>
              </div>

              <div className="col-pg"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="legal-container">
        <div className="legal-section">
          <div className="container-md">
            <div className="text-block">
              <p>
                The CyberCert Pty Ltd website uses Google Analytics, a web
                analytics service provided by Google. To analyse the usage of
                our website, Google Analytics uses cookies, a small piece of
                data that our website asks your browser to store on your PC,
                laptop, smartphone or another device. Cookies act like
                signposts, markers or tiny pieces of memory that enable us to
                give you the best experience possible when visiting our website.
              </p>
              <p>
                Cookies are used to store information, such as the time that the
                you visited our website, whether you have been to our website
                before, or what site referred you to our website.
              </p>
              <p>
                The information generated by the cookie about your use of our
                website (including your IP address) will be stored by Google in
                the United States. Google uses this information for the purpose
                of evaluating website usage and activity. Google will not
                associate your IP address with any other information held by
                Google. You can prevent your information being used by Google
                Analytics, using Google Analytics Opt-out service,{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here.
                </a>
              </p>
              <p>
                You may also choose to disable all cookies. The use of cookies
                by CyberCert, however, supports your use of our website.
                Disabling our ability to deploy cookies may prevent you from
                using the website with ease.
              </p>
              <p>
                If you wish to disable, prevent or clear all cookies from your
                browser, you can follow the instructions available on your web
                browser,{" "}
                <a
                  href="https://www.allaboutcookies.org/verify"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here.
                </a>
              </p>
              <p>
                If you have any questions or concerns about our use of cookies,
                please contact us using our{" "}
                <a href="./index.html#contactSection">Contact Us</a> form
                available on our website (
                <a
                  href="https://www.cybercert.com.au/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.cybercert.com.au
                </a>
                ), or via the details set out below:
              </p>
            </div>
            <div className="text-block">
              <h3>Mail:</h3>
              <p>
                CyberCert
                <br />
                15 Moore Street
                <br />
                Canberra ACT 2601
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
