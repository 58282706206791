import { Link, useLocation } from "react-router-dom";

export function NavBreadcrumb({ item }) {
  const location = useLocation();
  const data = {
    class: location.pathname === item?.to ? "active" : "",
    to: "",
    name: "",
    ...item,
  };
  return (
    <li className={data.class}>
      <Link to={data.to}>{data.name}</Link>
    </li>
  );
}
