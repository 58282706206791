import {
  getCertificationProgress,
  getCertificationProgressesByABN,
  getOrganisation,
  getUserByABN,
  updateCertificationProgress,
} from "api";
import { spinner } from "assets";
import { NavBreadcrumbList, Spinner } from "components";
import { useLoadingState } from "middleware";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { ROUTE } from "variables";
import "./index.scss";
import { CertificationService } from "services/certification";

export function ViewCertification() {
  const { loading, setLoading } = useLoadingState();
  const [certification, setCertification] = useState();
  const [organisation, setOrganisation] = useState();
  const params = useParams();

  useEffect(() => {
    setupCertification();
  }, []);

  const setupCertification = async () => {
    setLoading(true);

    const id = params?.id;
    const c = await getCertificationProgress(id);

    if (!!c) {
      setCertification(c);
      const o = await getOrganisation(c.ABN);

      setOrganisation(o);
    }

    setLoading(false);
  };

  const passAudit = async () => {
    setLoading(true);

    const certProgress = await getCertificationProgress(certification?.id);

    if (certProgress?.current !== 5) {
      console.error("Illegal state.");
      setLoading(false);
      return;
    }

    await CertificationService.passAudit(certProgress);

    setLoading(false);
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}
      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.CERTIFICATIONS, name: "Certifications" },
                      {
                        to: ROUTE.VIEW_CERTIFICATION.replace(
                          ":id",
                          certification?.id
                        ),
                        name: `Certification #${certification?.id
                          ?.slice(-4)
                          ?.toUpperCase()}`,
                      },
                    ]}
                  />
                  <h1 className="title">Certifications</h1>
                  {/* <div className="mt-30"></div> */}
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>

            {/* <AdminSearch name="partner" searchBy="ABN" searchFn={""} /> */}
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <Link to={ROUTE.CERTIFICATIONS}>
              <div className="mt-30 back-to-accounts">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.39238 14.8999C3.30846 14.8999 -0.000404588 11.5878 -0.000404945 7.4999C-0.000405303 3.412 3.30846 0.0999036 7.39237 0.0999032C11.4763 0.0999029 14.7852 3.412 14.7852 7.4999C14.7852 11.5878 11.4763 14.8999 7.39238 14.8999ZM8.25387 10.6151L6.00325 8.45474L11.4465 8.45474C11.8429 8.45474 12.1619 8.13547 12.1619 7.73861L12.1619 7.26119C12.1619 6.86434 11.8429 6.54506 11.4465 6.54506L6.00325 6.54506L8.25387 4.38474C8.54303 4.10724 8.54899 3.64474 8.2658 3.36127L7.93789 3.03603C7.65768 2.75555 7.20457 2.75555 6.92735 3.03603L2.97161 6.99265C2.6914 7.27313 2.6914 7.72668 2.97161 8.00418L6.92735 11.9668C7.20756 12.2472 7.66066 12.2472 7.93789 11.9668L8.2658 11.6415C8.54899 11.3551 8.54303 10.8926 8.25387 10.6151V10.6151Z"
                    fill="#2078EC"
                  />
                </svg>
                Back to all certifications
              </div>
            </Link>

            <div className="mt-30 content-admin">
              <div className="account-actions-view">
                <div className="heading">
                  <h1 className="content-admin-title large">
                    {`Certification #${certification?.id
                      ?.slice(-4)
                      ?.toUpperCase()}`}
                  </h1>
                  <p>
                    Certification ID{" "}
                    <span className="id">
                      {certification?.certID || certification?.id}
                    </span>
                  </p>
                </div>
                {/* <div className="manage">
                      {created === true && (
                        <div>
                          <p className="notification">
                            {" "}
                            Account created successfully!{" "}
                          </p>
                        </div>
                      )}
                      <div className="action">
                        <img src={edit_account} alt="Edit account" />
                        Delete
                        <img src={delete_account} alt="Delete account" />
                      </div>
                    </div> */}
              </div>
              <div className="f-row-start">
                <div className="org-details">
                  <p className="label">Certification details</p>
                  <div>
                    <p>
                      <b>ABN</b> {certification?.ABN}
                    </p>
                    <p>
                      <b>Organisation Name</b>{" "}
                      <span className="capitalize">{organisation?.name}</span>
                    </p>
                    <p>
                      <b>Certification Level</b> {certification?.level}
                    </p>
                    <p>
                      <b>Status</b> {certification?.status}
                    </p>
                    <p>
                      <b>Last Update</b> {certification?.updatedAt}
                    </p>
                  </div>
                </div>
                <div className="f-col-start account-type-options">
                  <div className="title">Certification Options</div>
                  <div>
                    {(certification?.level === 4 ||
                      certification?.level === 5) &&
                    certification?.current === 5 ? (
                      <button
                        className="btn btn-warning-gd"
                        onClick={passAudit}
                      >
                        Pass Audit
                      </button>
                    ) : (
                      <div>No action available.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
