import { updateCustomer } from "graphql/mutations";
import { Button } from "react-bootstrap";
import { ROUTE } from "variables";

export function CreateGuide({ ABN }) {
  const createGuide = async () => {
    await updateCustomer({
      ABN,
      guidePurchased: true,
    });

    window.location.href = `${ROUTE.ROOT}?ABN=${ABN}`;
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={createGuide}>
        Buy Guide
      </Button>
    </div>
  );
}
