import { spinner } from "assets";
import { FormInput } from "components/form-input";
import { Spinner } from "components/loading";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function PartnerOptionAdd({
  input,
  cfm,
  handleInput,
  showPanel,
  closePanel,
  showCfmPanel,
  closeCfmPanel,
  process,
  confirm,
  showResult,
  closeResultPanel,
  addResult,
}) {
  const [loading, setLoading] = useState();
  const [err, setErr] = useState();

  const handleProcess = async () => {
    setLoading(true);

    const result = await process();
    setErr(result);

    setLoading(false);
  };

  const handleConfirm = async () => {
    setLoading(true);

    const result = await confirm();
    setErr(result);

    setLoading(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleInput({
      target: {
        name,
        value: value.replace(/\s/g, ""),
      },
    });
  };

  return (
    <>
      <Modal show={showPanel} onHide={closePanel}>
        <Modal.Header closeButton>
          <Modal.Title>Add partner entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <h2>1. Partner Type</h2>

          <FormInput
            controlId="addPartnerCAP"
            label="Partner Program"
            type="text"
            name="isTypeCAP"
            value="CAP"
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            disabled
          />

          <FormInput
            controlId="addPartnerCertification"
            label="Prepopulate Partner Certification Dashboard"
            type="select"
            name="addT2Cert"
            options={["None", "Bronze", "Silver", "Gold"]}
            value={input?.addT2Cert || "None"}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <h3>Consultant</h3>
          <FormInput
            controlId="addPartnerConsultant"
            label="Consultant Type"
            type="select"
            name="isTypeConsultant"
            options={["true", "false"]}
            value={input?.isTypeConsultant || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {input?.isTypeConsultant === "true" && (
            <>
              <FormInput
                controlId="addPartnerConsultantBronzeQTY"
                label="Consultant Bronze Qty"
                type="text"
                name="addPartnerConsultantBronzeQTY"
                value={input?.addPartnerConsultantBronzeQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />

              <FormInput
                controlId="addPartnerConsultantSilverQTY"
                label="Consultant Silver Qty"
                type="text"
                name="addPartnerConsultantSilverQTY"
                value={input?.addPartnerConsultantSilverQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />

              <FormInput
                controlId="addPartnerConsultantGoldQTY"
                label="Consultant Gold Qty"
                type="text"
                name="addPartnerConsultantGoldQTY"
                value={input?.addPartnerConsultantGoldQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
            </>
          )}

          <h3>MSP</h3>
          <FormInput
            controlId="addPartnerMSP"
            label="MSP Type"
            type="select"
            name="isTypeMSP"
            options={["true", "false"]}
            value={input?.isTypeMSP || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {input?.isTypeMSP === "true" && (
            <>
              <FormInput
                controlId="addPartnerMSPBronzeQTY"
                label="MSP Bronze Qty"
                type="text"
                name="addPartnerMSPBronzeQTY"
                value={input?.addPartnerMSPBronzeQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerMSPSilverQTY"
                label="MSP Silver Qty"
                type="text"
                name="addPartnerMSPSilverQTY"
                value={input?.addPartnerMSPSilverQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerMSPGoldQTY"
                label="MSP Gold Qty"
                type="text"
                name="addPartnerMSPGoldQTY"
                value={input?.addPartnerMSPGoldQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
            </>
          )}

          <h3>Membership</h3>
          <FormInput
            controlId="addPartnerMembership"
            label="Membership Type"
            type="select"
            name="isTypeMembership"
            options={["true", "false"]}
            value={input?.isTypeMembership || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {input?.isTypeMembership === "true" && (
            <>
              <FormInput
                controlId="addPartnerMembershipBronzeQTY"
                label="Membership Bronze Qty"
                type="text"
                name="addPartnerMembershipBronzeQTY"
                value={input?.addPartnerMembershipBronzeQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerMembershipSilverQTY"
                label="Membership Silver Qty"
                type="text"
                name="addPartnerMembershipSilverQTY"
                value={input?.addPartnerMembershipSilverQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerMembershipGoldQTY"
                label="Membership Gold Qty"
                type="text"
                name="addPartnerMembershipGoldQTY"
                value={input?.addPartnerMembershipGoldQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
            </>
          )}

          <h3>Referrer</h3>
          <FormInput
            controlId="addPartnerReferrer"
            label="Referrer Type"
            type="select"
            name="isTypeReferrer"
            options={["true", "false"]}
            value={input?.isTypeReferrer || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {input?.isTypeReferrer === "true" && (
            <>
              <FormInput
                controlId="addPartnerReferrerBronzeQTY"
                label="Referrer Bronze Qty"
                type="text"
                name="addPartnerReferrerBronzeQTY"
                value={input?.addPartnerReferrerBronzeQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerReferrerSilverQTY"
                label="Referrer Silver Qty"
                type="text"
                name="addPartnerReferrerSilverQTY"
                value={input?.addPartnerReferrerSilverQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerReferrerGoldQTY"
                label="Referrer Gold Qty"
                type="text"
                name="addPartnerReferrerGoldQTY"
                value={input?.addPartnerReferrerGoldQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
            </>
          )}

          <h3>Supply Chain</h3>
          <FormInput
            controlId="addPartnerSupplyChain"
            label="Supply Chain Type"
            type="select"
            name="isTypeSupplyChain"
            options={["true", "false"]}
            value={input?.isTypeSupplyChain || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {input?.isTypeSupplyChain === "true" && (
            <>
              <FormInput
                controlId="addPartnerSupplyChainBronzeQTY"
                label="Supply Chain Bronze Qty"
                type="text"
                name="addPartnerSupplyChainBronzeQTY"
                value={input?.addPartnerSupplyChainBronzeQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerSupplyChainSilverQTY"
                label="Supply Chain Silver Qty"
                type="text"
                name="addPartnerSupplyChainSilverQTY"
                value={input?.addPartnerSupplyChainSilverQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerSupplyChainGoldQTY"
                label="Supply Chain Gold Qty"
                type="text"
                name="addPartnerSupplyChainGoldQTY"
                value={input?.addPartnerSupplyChainGoldQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
            </>
          )}

          <h4>Franchise</h4>
          <FormInput
            controlId="addPartnerFranchise"
            label="Franchise Type"
            type="select"
            name="isTypeFranchise"
            options={["true", "false"]}
            value={input?.isTypeFranchise || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {input?.isTypeFranchise === "true" && (
            <>
              <FormInput
                controlId="addPartnerFranchiseBronzeQTY"
                label="Franchise Bronze Qty"
                type="text"
                name="addPartnerFranchiseBronzeQTY"
                value={input?.addPartnerFranchiseBronzeQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerFranchiseSilverQTY"
                label="Franchise Silver Qty"
                type="text"
                name="addPartnerFranchiseSilverQTY"
                value={input?.addPartnerFranchiseSilverQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
              <FormInput
                controlId="addPartnerFranchiseGoldQTY"
                label="Franchise Gold Qty"
                type="text"
                name="addPartnerFranchiseGoldQTY"
                value={input?.addPartnerFranchiseGoldQTY || 0}
                onChange={handleInput}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
              />
            </>
          )}

          <h2>2. Partner Benefits</h2>

          <FormInput
            controlId="addPartnerSubscriptionDiscount"
            label="Subscription Discount (%)"
            type="text"
            name="subscriptionDiscount"
            value={input?.subscriptionDiscount}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addPartnerCodeExpiry"
            label="Discount Expiry Date"
            type="date"
            name="codeExpiry"
            value={input?.codeExpiry}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addPartnerVolumeDiscount"
            label="Volume Subscription Discount (%)"
            type="text"
            name="volumeDiscount"
            value={input?.volumeDiscount}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addPartnerMinimumVolume"
            label="Minimum Volume Purchase (Subscriptions)"
            type="text"
            name="minimumVolume"
            value={input?.minimumVolume}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <h2>3. Organisation Details</h2>

          <FormInput
            controlId="addPartnerABN"
            label="ABN"
            type="text"
            name="ABN"
            value={input?.ABN}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addPartnerName"
            label="Legal Entity Name"
            type="text"
            name="entityName"
            value={input?.entityName}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addEntityType"
            label="Entity Type"
            type="text"
            name="entityType"
            value={input?.entityType}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addLocation"
            label="Location"
            type="text"
            name="entityAddressStreet"
            value={input?.entityAddressStreet}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="preferredEntityName"
            label="Preferred Entity Name"
            type="text"
            name="name"
            value={input?.name}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addWebsite"
            label="Website URL"
            type="text"
            name="website"
            value={input?.website}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <h2>4. Dashboard Admin User Details</h2>

          <FormInput
            controlId="addPartnerFistName"
            label="First Name"
            type="text"
            name="firstName"
            value={input?.firstName}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addPartnerLastname"
            label="Last Name"
            type="text"
            name="lastName"
            value={input?.lastName}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addPartnerEmail"
            label="Work Email Address"
            type="text"
            name="email"
            value={input?.email}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addPartnerPhone"
            label="Work Mobile Phone"
            type="text"
            name="phone"
            value={input?.phone}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addPartnerRole"
            label="Role"
            type="text"
            name="role"
            value={input?.role}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          {!!err && (
            <div className={`form-validation error`}>
              <div className="validation-txt">
                <p className="v-text error">{err}</p>
              </div>
            </div>
          )}

          <Button onClick={handleProcess}>Add/Update</Button>
        </Modal.Body>
      </Modal>

      <Modal show={showCfmPanel && !!cfm} onHide={closeCfmPanel}>
        <Modal.Header closeButton>
          <Modal.Title>
            {cfm?.existed === true ? "Update" : "Add"} partner entry
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}
          {cfm?.existed === true && (
            <>This partner account already existed. Update its detail?</>
          )}

          {!!cfm && (
            <>
              <div>
                <div>
                  <>ABN:</> <>{cfm?.ABN}</>
                </div>

                <div>
                  <>Phone:</> <>{cfm?.phone}</>
                </div>

                <div>
                  <>Preferred Name:</> <>{cfm?.name}</>
                </div>

                <div>
                  <>Email:</> <>{cfm?.email}</>
                </div>

                <div>
                  <>Partner program:</> <>{cfm?.isTypeCAP ? "CAP" : "NA"}</>
                </div>

                <div>
                  <>Partner type:</>{" "}
                  <>
                    {[
                      "isTypeReferrer",
                      "isTypeSupplyChain",
                      "isTypeMembership",
                      "isTypeConsultant",
                      "isTypeFranchise",
                      "isTypeMSP",
                      "isTypeVendor",
                    ]
                      .filter((t) => cfm[t])
                      .map((k) => {
                        return {
                          isTypeReferrer: "Referrer",
                          isTypeSupplyChain: "Supply Chain",
                          isTypeMembership: "Membership",
                          isTypeConsultant: "Consultant",
                          isTypeFranchise: "Franchise",
                          isTypeMSP: "MSP",
                          isTypeVendor: "Vendor",
                        }[k];
                      })
                      .join(", ")}
                  </>
                </div>

                <div>
                  <>Code Expiry:</> <>{cfm?.codeExpiry}</>
                </div>

                <div>
                  <>Minimum Volume:</> <>{cfm?.minimumVolume}</>
                </div>

                <div>
                  <>Subscription Discount:</> <>{cfm?.subscriptionDiscount}</>
                </div>

                <div>
                  <>Volume Subscription Discount:</> <>{cfm?.volumeDiscount}</>
                </div>

                <div>
                  <>Website:</> <>{cfm?.website}</>
                </div>

                <div>
                  <>Fist Name:</> <>{cfm?.firstName}</>
                </div>

                <div>
                  <>Last Name:</> <>{cfm?.lastName}</>
                </div>

                <div>
                  <>Role:</> <>{cfm?.role}</>
                </div>
              </div>

              <Button onClick={handleConfirm}>
                {cfm?.existed === true ? <>Update</> : <>Add</>}
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showResult} onHide={closeResultPanel}>
        <Modal.Header closeButton>
          <Modal.Title>Created partner</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!!addResult && (
            <>
              <div>
                <div>
                  <>ABN: </>

                  <>{addResult?.partner?.ABN}</>
                </div>

                <div>
                  <>Name: </>

                  <>{addResult?.partner?.name}</>
                </div>

                <div>
                  <>Invites:</>

                  <div>
                    {Object.keys(addResult?.inviteLinks).map((k) =>
                      !!addResult?.inviteLinks?.[k]?.code ? (
                        <>
                          <div>
                            <>{k}: </>
                            <>{`/invite/${addResult?.inviteLinks?.[k]?.code}`}</>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                </div>
              </div>
              <Button onClick={closeResultPanel}>Close</Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
