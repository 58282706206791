import { Asterisk } from "components/required-asterisk";
import { useState } from "react";
import { Form } from "react-bootstrap";

export function FormInputCode({
  controlId,
  label,
  name,
  onChange,
  length = 6,
  required,
  successTxt = "",
  errorTxt = "",
  value,
  pValidation,
  pSetValidation,
}) {
  let [validation, setValidation] = useState("NA");

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  const validateInput = (event) => {
    const value = event.target.value;
    if (!required || !!value) {
      setValidation("success");
    } else {
      setValidation("error");
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>   
      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>
      
      <Form.Group className="mb-3" controlId={controlId}>
        <Form.Label className="form-label">
          <div>{label}</div>
          {required && <Asterisk />}
        </Form.Label>

        <Form.Control
          className="form-input code-input"
          type="text"
          autoComplete="one-time-code"
          name={name}
          defaultValue={value}
          onChange={validateInput}
          onBlur={validateInput}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder={null}
          maxLength={length}
        />
      </Form.Group>
    </div>
  );
}
