import { makeGetFunction } from "api/utils";
import { listReports as listAPI } from "graphql/queries";

export const listReports = async (abn, filter) =>
  (
    await makeGetFunction({
      name: "listReports",
      query: listAPI,
      params: {
        filter: {
          ...filter,
          name: {
            contains: abn,
          },
        },
        limit: 10000,
      },
    })()
  ).items || [];
