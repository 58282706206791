import { listCertBundles } from "api";
import { spinner } from "assets";
import { FormInput } from "components/form-input";
import { Spinner } from "components/loading";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export function PartnerOptionAddReferrer({
  input,
  handleInput,
  showPanel,
  closePanel,
  showCfmPanel,
  closeCfmPanel,
  process,
  confirm,
}) {
  const [loading, setLoading] = useState();
  const [err, setErr] = useState(null);
  const [addReferrerData, setAddReferrerData] = useState({});

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    let certBundles = await listCertBundles();

    setAddReferrerData({
      ...addReferrerData,
      certBundles,
    });
  }

  const handleProcess = async () => {
    setLoading(true);

    handleConfirm();

    setLoading(false);
  };

  const handleConfirm = async () => {
    setErr(null);

    setLoading(true);

    let confirmInput = {
      ...input,
      certBundle: input?.certBundle?.id,
    };
    if (
      !["firstName", "lastName", "email", "linkToReferrer"].every(
        (k) => !!confirmInput[k]
      )
    ) {
      setErr("Please fill in all required fields.");
    } else {
      let result = await confirm(confirmInput);
      setErr(result || undefined);
    }

    setLoading(false);
  };

  const handleCertBundleInput = (event) => {
    const bundle = addReferrerData.certBundles.find(
      (bundle) => bundle.bundleName === event.target.value
    );
    handleInput({
      target: {
        name: "certBundle",
        value: bundle,
      },
    });
  };

  return (
    <>
      <Modal show={showPanel} onHide={closePanel}>
        <Modal.Header closeButton>
          <Modal.Title>Add Referrer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="addReferrerFirstName"
            label="First Name"
            type="text"
            name="firstName"
            value={input?.firstName}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addReferrerLastName"
            label="Last Name"
            type="text"
            name="lastName"
            value={input?.lastName}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addReferrerEmail"
            label="Email"
            type="text"
            name="email"
            value={input?.email}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addReferrerABN"
            label="Partner ABN"
            type="text"
            name="ABN"
            value={input?.ABN}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addReferrerLinkToReferrer"
            label="Link to Referrer"
            type="select"
            name="linkToReferrer"
            options={["true", "false"]}
            value={input?.linkToReferrer || false}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addReferrerLinkToReferrer"
            label="Certification Bundle"
            type="select"
            name="linkToReferrer"
            options={[
              null,
              ...(addReferrerData?.certBundles?.map(
                (bundle) => bundle.bundleName
              ) || []),
            ]}
            value={input?.certBundle || ""}
            onChange={handleCertBundleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {!!input?.certBundle && (
            <div>
              <p>Selected bundle: {input?.certBundle?.bundleName}</p>
              <p>Bronze: {input?.certBundle?.bronzeQTY || 0}</p>
              <p>Silver: {input?.certBundle?.silverQTY || 0}</p>
              <p>Gold: {input?.certBundle?.goldQTY || 0}</p>
              <p>Platinum: {input?.certBundle?.platinumQTY || 0}</p>
              <p>Diamond: {input?.certBundle?.diamondQTY || 0}</p>
            </div>
          )}

          {err !== null && (
            <div className={`form-validation ${!!err ? "error" : "success"}`}>
              <div className="validation-txt">
                {!!err ? (
                  <p className="v-text error">{err}</p>
                ) : (
                  <p className="v-text success">Added successfully.</p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleProcess}>
            Process
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
