import { configureStore } from "@reduxjs/toolkit";
import sessionSliceReducer from "./slices/sessionSlice";
import loadingReducer from "./slices/loadingSlice";

export const store = configureStore({
  reducer: {
    appSession: sessionSliceReducer,
    loading: loadingReducer,
  },
});
