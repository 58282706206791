import { API } from "aws-amplify";

export const clearUserAccount = async (username) => {
  const myInit = {
    queryStringParameters: {
      username,
    },
    body: {
      username,
    },
  };
  const result = await API.post(
    "apiAdmin",
    `/deleteUser?username=${username}`,
    myInit
  );
  // console.log(result);
  return result;
};

export const getUserAccount = async ({ username, owner }) => {
  const myInit = {
    queryStringParameters: {
      username,
      owner,
    },
    body: {
      username,
      owner,
    },
  };
  const result = await API.get(
    "apiAdmin",
    `/getUser?username=${username}`,
    myInit
  );
  // console.log(result);
  return result;
};
