import { deleteActivity as deleteActivityItem, listActivities } from "api";
import { Spinner } from "components/loading";
import { RecentActivityItem } from "components/recent-activity-item";
import { RecentActivityItemDeleteModal } from "components/recent-activity-item/delete-modal";
import { ROUTE } from "variables";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export function DashboardRecentActivitySection() {
  const [activities, setActivities] = useState();
  const deleteRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    const recentActivities = await listActivities({ limit: 5 });

    console.log(recentActivities);

    setActivities(recentActivities);
  };

  const deleteActivity = async (item) => {
    if (!!item?.id) {
      await deleteActivityItem(item.id);
      setup();
    }
  };

  return (
    <>
      <div className="admin-section-title recent-activity">
        <div className="heading">
          <p className="activity-heading">
            <span>Recent activity</span>{" "}
            <span
              className="view-activities"
              onClick={() => {
                navigate(ROUTE.ACTIVITIES);
              }}
            >
              View all activities
            </span>
          </p>
        </div>

        {!activities && (
          <div className="content-admin admin-page">
            <div className="loading-spinner">
              <Spinner color="blue" size="large" />
            </div>
          </div>
        )}

        {!!activities && activities.items.length > 0 && (
          <>
            {activities.items.map((item) => (
              <RecentActivityItem
                item={item}
                deleteFn={() => deleteRef?.current?.show(item)}
              />
            ))}
          </>
        )}

        {!!activities && activities.items.length === 0 && (
          <div className="content-admin admin-page">No recent activities.</div>
        )}
      </div>

      <RecentActivityItemDeleteModal
        ref={deleteRef}
        deleteFn={deleteActivity}
      />
    </>
  );
}
