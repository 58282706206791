import { makeDeleteFunction } from "api/utils";
import { deleteCheckout as deleteAPI } from "graphql/mutations";
import { getCheckoutsForABN } from "./queries";

export const deleteCheckout = (id) =>
  makeDeleteFunction({
    name: "deleteCheckout",
    query: deleteAPI,
    params: {
      input: {
        id,
      },
    },
  })();

export const deleteCheckoutsForABN = async (ABN) => {
  const checkouts = await getCheckoutsForABN(ABN);

  await Promise.all(
    checkouts?.items?.map((checkout) => deleteCheckout(checkout?.id))
  );
};
