import { useState } from "react";
import Form from "react-bootstrap/Form";
import { Asterisk } from "../required-asterisk";

import "./form-input.scss";

export function FormTextareaInput({
  controlId,
  label,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
  required,
  disabled,
  successTxt = "",
  errorTxt = "Field required",
  pValidation,
  pSetValidation,
  autoComplete,
  rows,
  cols,
}) {
  let [validation, setValidation] = useState("NA");

  if (!!pValidation && !!pSetValidation) {
    validation = pValidation;
    setValidation = pSetValidation;
  }

  const validateInput = (event) => {
    if (!required || !!event.target.value) {
      setValidation("success");
    } else {
      setValidation("error");
    }
    onChange(event);
  };

  return (
    <div className={`form-validation ${validation}`}>
      <Form.Group className="mb-3 full-input" controlId={controlId}>
        <Form.Label className="form-label">
          <div>{label}</div>
          {required && <Asterisk />}
        </Form.Label>

        <Form.Control
          className="form-input"
          as="textarea"
          name={name}
          autoComplete={autoComplete}
          maxLength={maxLength}
          value={value}
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          onChange={validateInput}
          onBlur={validateInput}
          disabled={disabled}
        />
      </Form.Group>

      <div className="validation-txt">
        {!!successTxt && <p className="v-text success">{successTxt}</p>}
        {!!errorTxt && <p className="v-text error">{errorTxt}</p>}
      </div>
    </div>
  );
}
