import { forwardRef, useImperativeHandle, useState } from "react";

import "./pagination.scss";

export const SearchResultsWithPagination = forwardRef((props, ref) => {
  const { tableHeader, tableRow, pagination = 10, selectRef } = props;

  const [pages, setPages] = useState();
  const [curPage, setCurPage] = useState(0);

  const gotoPageFn = (page) => {
    setCurPage(page - 1);
  };

  useImperativeHandle(ref, () => ({
    setPages(itemsInput) {
      const items = itemsInput.filter((item) => !!item);
      let pagedItems = [[]];
      let curPage = 1;
      let i = 0;
      for (let idx = 0; idx < items.length; ++idx) {
        if (i >= pagination) {
          pagedItems.push([]);
          i = 0;
          curPage++;
        }
        pagedItems[curPage - 1].push(items[idx]);
        i++;
      }
      setPages(pagedItems);
      gotoPageFn(1);
    },
  }));

  return (
    <>
      <div className="mt-30 card-compl-admin">
        <div className="table-holder">
          <div className="table-wrap">
            {!!pages && pages.length > 0 && (
              <div className="search-result partners">
                <table className="table full-width">
                  {tableHeader}

                  <tbody ref={selectRef}>
                    {pages[curPage]?.map((item) => (
                      <>{tableRow(item)}</>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-30 account-actions">
        <div className="pagination">
          {(pages || [])[curPage]?.length ? (
            <>
              {curPage * pagination + 1}-
              {curPage * pagination + (pages || [])[curPage]?.length}
            </>
          ) : (
            <>0</>
          )}{" "}
          of {pages?.flatMap((c) => c)?.length || 0} items{" "}
          {/*(Page {curPage + 1})*/}
        </div>
        <div className="pagination">
          Page{" "}
          {!!pages && pages.length > 0 ? (
            pages?.map((page, idx) => (
              <a className="page-link" onClick={() => gotoPageFn(idx + 1)}>
                {idx + 1}
              </a>
            ))
          ) : (
            <a className="page-link">1</a>
          )}
        </div>
      </div>
    </>
  );
});
