import { FormInput } from "components/form-input";
import { useState } from "react";
import { Button } from "react-bootstrap";

export function AdminSearch({ name, searchBy, searchFn, setParentSearchRef }) {
  const [search, setSearch] = useState();
  const [searchRef, setSearchRef] = useState();

  return (
    <>
      <FormInput
        controlId={`search${name}`}
        label={`Search by ${searchBy}`}
        type="search"
        name={name}
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
          if (!!setParentSearchRef) {
            setParentSearchRef(event.target.value);
          }
        }}
        errorTxt={""}
        pValidation={null}
        pSetValidation={() => {}}
        setRef={setSearchRef}
        required
      />

      <Button
        varian="secondary"
        className="btn btn-warning-gd"
        onClick={() => {
          searchFn(search);
        }}
      >
        Search
      </Button>
    </>
  );
}
