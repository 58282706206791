import { API } from "aws-amplify";

export const resendConfirmationCode = async ({ email }) => {
  const myInit = {
    body: {
      type: "ACCOUNT_VERIFY",
      email,
    },
  };
  const result = await API.post("apiAdmin", "/email", myInit);
  //   console.log(result);
  return result;
};

export const sendWelcomeEmail = async ({ email }) => {
  const myInit = {
    body: {
      type: "USER_WELCOME",
      email,
    },
  };
  const result = await API.post("apiAdmin", "/email", myInit);
  //   console.log(result);
  return result;
};

export const sendSignRequestEmail = async ({ progress }) => {
  const myInit = {
    body: {
      type: "SIGN_ATTESTATION",
      progress,
    },
  };
  const result = await API.post("apiAdmin", "/email", myInit);
  //   console.log(result);
  return result;
};

export const sendCompletedCertEmail = async ({
  to,
  name,
  id,
  level,
  completedDate,
  expiredDate,
}) => {
  const myInit = {
    body: {
      type: "COMPLETE_CERT",
      params: {
        to,
        name,
        id,
        level,
        completedDate,
        expiredDate,
      },
    },
  };
  const result = await API.post("apiAdmin", "/email", myInit);
  //   console.log(result);
  return result;
};
