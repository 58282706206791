import { Asterisk } from "components/required-asterisk";
import Form from "react-bootstrap/Form";

import "./form-input.scss";

export function FormDropdownInput({
  controlId,
  options = [],
  label,
  name,
  placeholder,
  value,
  onChange,
  maxLength,
  required,
  disabled,
}) {
  return (
    <Form.Group className="form-select" controlId={controlId}>
      <Form.Label className="form-label">
        <div>{label}</div>
        {required && <Asterisk />}
      </Form.Label>

      <Form.Control
        className="form-input"
        as="select"
        name={name}
        maxLength={maxLength}
        defaultValue={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map((o, i) => (
          <option key={i} value={o}>
            {o}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}
