import { getPartner as getAPI, listPartners } from "graphql/queries";
import { makeGetFunction } from "../../utils";

export const getPartner = (ABN) =>
  makeGetFunction({
    name: "getPartner",
    query: getAPI,
    params: {
      ABN,
    },
  })();

export const getPartnersByPhone = (phone) =>
  !!phone
    ? makeGetFunction({
        name: "listPartners",
        query: listPartners,
        params: {
          filter: {
            phone: {
              eq: phone,
            },
          },
        },
      })()
    : undefined;
