import { API } from "aws-amplify";

export const getGeneratedReport = (report) => {
  const myInit = {
    queryStringParameters: {
      item: "REPORT",
      name: report,
    },
  };

  return API.get("privateDoc", `/admin?item=REPORT&name=${report}`, myInit);
};
