import { makeGetFunction } from "api/utils";
import { listCheckouts as listAPI } from "graphql/queries";

export const getCheckoutsForABN = (ABN, filter) =>
  makeGetFunction({
    query: listAPI,
    name: "listCheckouts",
    params: {
      filter: {
        ABN: {
          eq: ABN,
        },
        ...filter,
      },
    },
  })();
