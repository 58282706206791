import { makeCreateFunction, makeDeleteFunction } from "api/utils";
import {
  createActivityFeed as createAPI,
  deleteActivityFeed as deleteAPI,
} from "graphql/mutations";

export const createCertCompleteActivity = ({ ABN, level, name }) =>
  makeCreateFunction({
    name: "createActivityFeed",
    query: createAPI,
    params: {
      input: {
        ABN,
        level,
        name,
        type: "COMPLETE_CERTIFICATE",
        self: "Activity",
      },
    },
  })();

export const createActivity = ({ ABN, level, email, type, name }) =>
  makeCreateFunction({
    name: "deleteActivityFeed",
    query: createAPI,
    params: {
      input: {
        ABN,
        level,
        name,
        self: "Activity",
        email,
        type,
      },
    },
  })();

export const deleteActivity = (id) =>
  makeDeleteFunction({
    name: "deleteActivity",
    query: deleteAPI,
    params: {
      input: {
        id,
      },
    },
  })();
