import { makeGetFunction } from "api/utils";
import { getCustomer as getAPI } from "graphql/queries";

export const getCustomer = (ABN) =>
  makeGetFunction({
    name: "getCustomer",
    query: getAPI,
    params: {
      ABN,
    },
  })();
