import { makeUpdateFunction } from "api/utils";
import {
  deleteCustomer as deleteAPI,
  updateCustomer as updateAPI,
} from "graphql/mutations";

export const updateCustomer = (input) =>
  makeUpdateFunction({
    name: "updateCustomer",
    query: updateAPI,
    params: {
      input,
    },
  })();

export const deleteCustomer = (input) =>
  makeUpdateFunction({
    name: "deleteCustomer",
    query: deleteAPI,
    params: {
      input,
    },
  })();
