import { CERTIFICATION_INFO } from "variables";

export function SendAttestation({ item }) {
  return (
    <div>
      <strong className="org-name">
        <span>{item?.name}</span>
      </strong>{" "}
      ({item?.ABN}) sent <a className="link">{item?.email}</a> a request to sign
      Attestation letter for a{" "}
      <a className="link">
        Level {item?.level} - {CERTIFICATION_INFO[item?.level].marketingName}
      </a>{" "}
    </div>
  );
}
