import { API } from "aws-amplify";

export const downloadRequirementsFile = async () => {
  const myInit = {
    queryStringParameters: {},
  };

  const result = await API.get("certification", `/getRequirementsFile`, myInit);
  return result?.result?.data;
};

export const uploadeloadRequirementsFile = async ({
  data,
  multiple = false,
  update = false,
}) => {
  const myInit = {
    body: {
      data,
      multiple,
      update
    },
  };

  const result = await API.post(
    "certification",
    `/updateRequirementsFile`,
    myInit
  );
  return result?.result?.data;
};
