const { API } = require("aws-amplify");

export const removePartnerAccount = async ({ ABN }) => {
  const myInit = {
    body: {
      ABN,
    },
  };
  const result = await API.post("apiAdmin", "/partner/remove", myInit);
  // console.log(result);
  return result;
};
