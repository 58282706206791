import { getCampaign, getSales, getSupplyChainEntityByABN } from "api";
import { spinner } from "assets";
import { AdminSearch, NavBreadcrumbList, Spinner } from "components";
import { NewTabSvg } from "components/svgs/new-tab";
import { ROUTE } from "variables";
import { COMMISSION_ITEMS, COMMISSION_TYPE } from "variables/commission";
import { useLoadingState } from "middleware";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { parseDate } from "services";

import "./index.scss";

export function ViewSale() {
  const { loading, setLoading } = useLoadingState();
  const [sale, setSale] = useState();
  const [company, setCompany] = useState();
  const [campaign, setCampaign] = useState();
  const params = useParams();

  useEffect(() => {
    setupSale();
  }, []);

  const setupSale = async () => {
    setLoading(true);

    const id = params?.id;
    const s = await getSales(id);

    if (!!s) {
      setSale(s);

      const supplier = await getSupplyChainEntityByABN(s.supplier);
      setCompany(supplier);

      if (!!s.campaign) {
        const camp = await getCampaign(s.campaign);
        setCampaign(camp);
      }
    }

    setLoading(false);
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.SALES, name: "Sales" },
                      {
                        to: ROUTE.VIEW_SALE.replace(":id", sale?.id),
                        name: `Sale #${sale?.id?.slice(-4)?.toUpperCase()}`,
                      },
                    ]}
                  />
                  <h1 className="title">Sales</h1>
                  {/* <div className="mt-30"></div> */}
                </div>
                {/* <div className="col-pg back-to-dashboard"></div> */}
              </div>
            </div>

            {/* <AdminSearch name="partner" searchBy="ABN" searchFn={""} /> */}
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <Link to={ROUTE.SALES}>
              <div className="mt-30 back-to-accounts">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.39238 14.8999C3.30846 14.8999 -0.000404588 11.5878 -0.000404945 7.4999C-0.000405303 3.412 3.30846 0.0999036 7.39237 0.0999032C11.4763 0.0999029 14.7852 3.412 14.7852 7.4999C14.7852 11.5878 11.4763 14.8999 7.39238 14.8999ZM8.25387 10.6151L6.00325 8.45474L11.4465 8.45474C11.8429 8.45474 12.1619 8.13547 12.1619 7.73861L12.1619 7.26119C12.1619 6.86434 11.8429 6.54506 11.4465 6.54506L6.00325 6.54506L8.25387 4.38474C8.54303 4.10724 8.54899 3.64474 8.2658 3.36127L7.93789 3.03603C7.65768 2.75555 7.20457 2.75555 6.92735 3.03603L2.97161 6.99265C2.6914 7.27313 2.6914 7.72668 2.97161 8.00418L6.92735 11.9668C7.20756 12.2472 7.66066 12.2472 7.93789 11.9668L8.2658 11.6415C8.54899 11.3551 8.54303 10.8926 8.25387 10.6151V10.6151Z"
                    fill="#2078EC"
                  />
                </svg>
                Back to all sales
              </div>
            </Link>
            <div className="mt-30 content-admin">
              <div className="account-actions-view">
                <div className="heading">
                  <h1 className="content-admin-title large">
                    Sale #{sale?.id?.slice(-4)?.toUpperCase()}
                  </h1>
                  <p>
                    Sale ID <span className="id">{sale?.id}</span>
                  </p>
                </div>
                <div className="manage">
                  <div>
                    <p className="notification"></p>
                  </div>
                  <div className="action">
                    {/* <img src={edit_account} alt="Edit account" />
                    |
                    <img src={delete_account} alt="Delete account" /> */}
                  </div>
                </div>
              </div>
              <div className="f-row-start">
                <div className="org-details campaigns-details">
                  <div className="f-grid-two-col">
                    <div>
                      <div className="header first">Company</div>
                      <div className="value f-row-start-center svg-flex">
                        <Link>{company?.name}</Link>
                        <NewTabSvg />
                      </div>
                    </div>
                    <div>
                      <div className="header first">Amount paid</div>
                      <div className="value">
                        ${(sale?.payment || 0) / 100.0}
                      </div>
                    </div>
                  </div>
                  <div className="f-grid-two-col">
                    <div>
                      <div className="header">Date paid</div>
                      <div className="value f-row-start-center">
                        {parseDate(sale?.createdAt)}
                      </div>
                    </div>
                    <div>
                      <div className="header">Certificate Level</div>
                      <div className="value">{sale?.item}</div>
                    </div>
                  </div>
                  <div className="f-grid-two-col">
                    <div>
                      <div className="header">Type</div>
                      <div className="value f-row-start-center">
                        {COMMISSION_TYPE[sale?.category]}
                      </div>
                    </div>
                    <div>
                      <div className="header">Discount rate</div>
                      <div className="value">{sale?.discountRate}%</div>
                    </div>
                  </div>
                  <div>
                    <div className="header">Campaign</div>
                    <div className="value f-row-start-center svg-flex">
                      {!!campaign ? (
                        <>
                          <Link>{campaign?.name}</Link>
                          <NewTabSvg />
                        </>
                      ) : (
                        <>N/A</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="f-col-start account-sidebar">
                  <div className="label">Sale details</div>
                  <div>
                    <p>
                      <b>Company</b> <Link>{company?.name}</Link>
                    </p>
                    <p>
                      <b>ABN</b> {company?.ABN}
                    </p>
                    <p>
                      <b>Amount paid</b> ${(sale?.payment || 0) / 100.0}
                    </p>
                    <p>
                      <b>Date paid</b> {parseDate(sale?.createdAt)}
                    </p>
                    <p>
                      <b>Certificate Level</b> {COMMISSION_ITEMS[sale?.item]}
                    </p>
                    <p>
                      <b>Type</b> {COMMISSION_TYPE[sale?.category]}
                    </p>
                  </div>

                  {!!campaign && (
                    <>
                      <div className="mt-30 label">Campaign details</div>
                      <div>
                        <p>
                          <b>Campaign</b> <Link>{campaign?.name}</Link>
                        </p>
                        <p>
                          <b>Discount code</b> {campaign?.code || "N/A"}
                        </p>
                        <p>
                          <b>Discount rate</b> {campaign?.discountRate}%
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
