import { FormInput } from "components";
import { useLoadingState } from "middleware";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AuthService } from "services";

export function TOTPSettings({ onSuccess = () => {} }) {
  const { setLoading } = useLoadingState();
  const [user, setUser] = useState();
  const [setupCode, setSetupCode] = useState();
  const [generatedCode, setGeneratedCode] = useState();

  useEffect(() => {
    AuthService.getCurrentAuthSession().then((res) => {
      setUser(res);
      setLoading(false);
    });
  }, []);

  const setupTOTP = async () => {
    setLoading(true);
    const [user, code] = await AuthService.setupTOTP();
    setSetupCode(code);
    setUser(user);
    setLoading(false);
  };

  const disableTOTP = async () => {
    setLoading(true);
    await AuthService.disableTOTP();
    const newUser = await AuthService.getCurrentAuthSession();
    setUser(newUser);
    setLoading(false);
    window.location.reload();
  };

  const verifyTOTP = async () => {
    setLoading(true);
    await AuthService.verifyTOTP(generatedCode);
    setLoading(false);
    setSetupCode(null);
    setUser(null);
    const newUser = await AuthService.getCurrentAuthSession();
    setUser(newUser);
    onSuccess();
  };

  const reset = async () => {
    setLoading(true);
    const curUser = await AuthService.getCurrentAuthSession();
    setUser(curUser);
    setGeneratedCode(null);
    setSetupCode(null);
    setLoading(false);
  };

  return (
    <>
      <div>
        {!!user && (
          <>
            {user.preferredMFA === "NOMFA" ? (
              <>
                <Button onClick={setupTOTP}>Setup OTP</Button>
              </>
            ) : (
              <>
                <div>TOTP set for: {user.preferredMFA}</div>

                <Button onClick={disableTOTP}>Disable</Button>

                <div>
                  Or add new authenticator app (replace old authenticator app)
                </div>
                <Button onClick={setupTOTP}>Setup new code</Button>
              </>
            )}
          </>
        )}

        {!!setupCode && (
          <>
            <div>
              Add this code to your authenticator app (e.g. Google
              Authenticator):
            </div>
            <div className="setup-code">{setupCode}</div>

            {!!user && (
              <>
                <div>Or scan this QR code:</div>
                <div>
                  <QRCode
                    value={`otpauth://totp/AWSCognito:${user.username}?secret=${setupCode}&issuer=CyberCert`}
                  />
                </div>
              </>
            )}

            <div>Enter the generated code from your Authenticator app:</div>

            <FormInput
              controlId="confirmCode"
              label="Verification Code"
              type="code"
              name="code"
              onChange={(event) => {
                setGeneratedCode(event.target.value);
              }}
              required
            />

            <Button onClick={verifyTOTP} disabled={!generatedCode}>
              Submit
            </Button>

            <Button onClick={reset}>Cancel</Button>
          </>
        )}
      </div>
    </>
  );
}
