import {
  getGeneratedReport,
  getSupplyChainEntityByABN,
  listReports,
} from "api";
import { filter_account, spinner } from "assets";
import { AdminSearch, NavBreadcrumbList, Spinner } from "components";
import { ConfirmPopup } from "components/confirm";
import { SearchResultsWithPagination } from "components/pagination";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { parseDate } from "services";
import { ROUTE } from "variables";

const initialFilter = {
  type: {
    Partner: false,
    Supplier: false,
  },
  year: {
    thisYear: false,
    lastYear: false,
  },
};

const defaultFilterTypes = ["Partner", "Supplier"];

const defaultFilterYears = ["thisYear", "lastYear"];

export function AllReports() {
  const { loading, setLoading } = useLoadingState();
  const [showFilter, setShowFilter] = useState(false);
  const searchResultsRef = useRef();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [filterBy, setFilterBy] = useState(initialFilter);
  const filterRef = {
    type: {
      Partner: useRef(),
      Supplier: useRef(),
    },
    year: {
      thisYear: useRef(),
      lastYear: useRef(),
    },
  };
  const [searchRef, setSearchRef] = useState();
  const selectRef = useRef();
  const deleteRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    let filter = filterBy;
    // type
    params.type?.split(" ")?.forEach((p) => {
      if (Object.keys(initialFilter.type).includes(p)) {
        filter.type[p] = true;
      }
    });
    // year
    params.year?.split(" ")?.forEach((p) => {
      if (Object.keys(initialFilter.year).includes(p)) {
        filter.year[p] = true;
      }
    });
    if (!!params.abn) {
      performSearch(params.abn, filter);
    } else {
      performSearch("", filter);
    }
  }, []);

  const performSearch = async (value, filter = filterBy) => {
    setLoading(true);

    const result = await listReports(value);

    if (result.length > 0) {
      let updatedSearch = result;

      for (let i = 0; i < updatedSearch.length; ++i) {
        updatedSearch[i] = {
          ...updatedSearch[i],
          year: new Date(updatedSearch[i].sentAt).getFullYear(),
          orgName: (await getSupplyChainEntityByABN(updatedSearch[i].ABN))
            ?.name,
        };
      }

      if (!!filter) {
        let filterTypes = Object.keys(filter.type).filter(
          (f) => filter.type[f] === true
        );
        if (filterTypes.length === 0) {
          filterTypes = defaultFilterTypes;
        }
        let filterYear = Object.keys(filter.year).filter(
          (f) => filter.year[f] === true
        );
        if (filterYear.length === 0) {
          filterYear = defaultFilterYears;
        }

        filterYear = filterYear.map((y) => {
          if (y === "thisYear") {
            return new Date().getFullYear();
          }
          if (y === "lastYear") {
            return new Date().getFullYear() - 1;
          }
        });

        updatedSearch = updatedSearch
          .filter((r) => filterTypes.includes(r.type))
          .filter((r) => filterYear.includes(r.year));
      }

      searchResultsRef?.current?.setPages(updatedSearch);
    }

    setLoading(false);
  };

  const getSelectInputs = () => {
    return Array.from(selectRef.current.children).map(
      (child) => child.children[0].children[0].children[0]
    );
  };

  const selectAll = (e) => {
    const value = e.target.checked;
    const selectInputs = getSelectInputs();
    selectInputs.forEach((input) => {
      input.checked = value;
    });
  };

  const filterReports = () => {
    let updatedFilterBy = initialFilter;
    Object.keys(initialFilter.type).forEach((f) => {
      updatedFilterBy.type[f] = filterRef.type[f]?.current?.checked;
    });
    Object.keys(initialFilter.year).forEach((f) => {
      updatedFilterBy.year[f] = filterRef.year[f]?.current?.checked;
    });
    setFilterBy(updatedFilterBy);
    performSearch(searchRef, updatedFilterBy);
  };

  // const deleteSingleItem = async (id) => {
  //   getSelectInputs().forEach((item) => {
  //     if (item.getAttribute("id-key") === id) {
  //       item.checked = true;
  //     } else {
  //       item.checked = false;
  //     }
  //   });

  //   deleteRef.current?.show();
  // };

  const openReport = async (item) => {
    setLoading(true);
    const report = `${item.type.toLowerCase()}s/${item?.name}`;

    const url = await getGeneratedReport(report);

    if (!!url && url !== "/") {
      window.open(url, "_blank");
    }

    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.click();
    }
  };

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.REPORTS, name: "Reports" },
                    ]}
                  />
                  <h1 className="title">Reports</h1>
                  <div className="mt-30"></div>
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>

            <AdminSearch
              name="Partner"
              searchBy="ABN"
              searchFn={performSearch}
              setParentSearchRef={setSearchRef}
            />
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <div className="account-actions mt-30">
              <button
                className="filter"
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filter_account} alt="Filter account" />
                Filter
              </button>
              {/* <div className="manage">
                <div
                  className="action"
                  onClick={() => {
                    deleteRef.current?.show();
                  }}
                >
                  Delete
                  <img src={delete_account} alt="Delete reports" />
                </div>
              </div> */}
            </div>

            {showFilter ? (
              <div className="mt-30 content-admin">
                <div className="heading">
                  <h2 className="content-admin-title">Filter by Type</h2>
                </div>
                <div className="filter-checkbox">
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.type.Partner}
                        type="checkbox"
                        name=""
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">
                        Monthly Partner Report
                      </span>
                    </div>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.type.Supplier}
                        type="checkbox"
                        name=""
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">
                        Monthly Supplier Certification Report
                      </span>
                    </div>
                  </label>
                </div>
                <div className="heading">
                  <h2 className="content-admin-title">Filter by Year</h2>
                </div>
                <div className="filter-checkbox">
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.year.thisYear}
                        type="checkbox"
                        name=""
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">This Year</span>
                    </div>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.year.lastYear}
                        type="checkbox"
                        name=""
                        onKeyDown={handleKeyDown}
                      />
                      <span className="fake-checkbox">Last Year</span>
                    </div>
                  </label>
                </div>

                <div className="filter-controls">
                  <Button
                    className="btn btn-warning-gd"
                    onClick={filterReports}
                  >
                    Filter
                  </Button>
                  <Button
                    className="btn clear-btn"
                    onClick={async () => {
                      const filterRefs = Object.keys(filterRef).map(
                        (k) => filterRef[k]
                      );

                      filterRefs.forEach((fRef) => {
                        Object.keys(fRef).forEach((k) => {
                          if (!!fRef[k].current) {
                            fRef[k].current.checked = false;
                          }
                        });
                      });

                      filterReports();
                    }}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            ) : null}

            <SearchResultsWithPagination
              ref={searchResultsRef}
              selectRef={selectRef}
              tableHeader={
                <thead>
                  <tr>
                    {/* <td className="sticky-col select-col">
                      <label>
                        <input
                          onClick={selectAll}
                          className="fake-input"
                          type="checkbox"
                          name=""
                        />{" "}
                      </label>
                    </td>
                    <td className="sticky-col action-col"></td> */}
                    <td>ID</td>
                    <td>Organisation Name</td>
                    <td>Report</td>
                    <td>Type</td>
                    <td>Date Sent</td>
                    <td>Created</td>
                  </tr>
                </thead>
              }
              tableRow={(item) => (
                <tr>
                  {/* <td className="sticky-col select-col">
                    <label>
                      <input
                        id-key={item.id}
                        className="fake-input"
                        type="checkbox"
                        name=""
                      />
                    </label>
                  </td> 
                  <td className="sticky-col action-col">
                    <div className="edit-delete">
                      <img
                        onClick={() => {
                          deleteSingleItem(item?.id);
                        }}
                        src={delete_account}
                        alt="Delete account"
                      />
                    </div>
                  </td> */}
                  <td>{item.id}</td>
                  <td className="capitalize">{item?.orgName}</td>
                  <td className="capitalize">
                    <a className="link" onClick={() => openReport(item)}>
                      {item?.name}
                    </a>
                  </td>
                  <td>
                    {item.type === "Partner" ? (
                      <>
                        <p className="account-type partner-type">Partner</p>
                      </>
                    ) : (
                      ""
                    )}
                    {item.type === "Supplier" ? (
                      <>
                        <p className="account-type prime-type">Prime</p>
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{parseDate(item?.sentAt)}</td>
                  <td>{parseDate(item?.createdAt)}</td>
                </tr>
              )}
            />
          </div>
        </div>
      </div>

      <ConfirmPopup
        ref={deleteRef}
        title="Delete not allowed"
        body="Cannot delete record of reports"
        confirmTxt="Close"
        confirmFn={() => {}}
      />
    </>
  );
}
