import { makeGetFunction } from "api/utils";
import {
  getCertificationProgress as getAPI,
  listCertificationProgresses as listAPI,
} from "graphql/queries";

export const getCertificationProgress = (id) =>
  makeGetFunction({
    name: "getCertificationProgress",
    query: getAPI,
    params: {
      id,
    },
  })();

export const searchCertificationProgressesByABN = (ABN, filter = {}) =>
  makeGetFunction({
    name: "listCertificationProgresses",
    query: listAPI,
    params: {
      filter: {
        ABN: {
          contains: ABN || "",
        },
        ...filter,
      },
    },
  })();
