// import { Dashboard } from "pages/dashboard";

import {
  getCustomer,
  getOrganisation,
  getPartner,
  searchCertificationProgressesByABN,
  searchSalesByABN,
} from "api";
import { spinner } from "assets";
import {
  DashboardRecentActivitySection,
  FormInput,
  NavBreadcrumbList,
  Spinner,
} from "components";
import { MetaDescription } from "components/meta-manager/MetaDescription";
import { LinkArrowSvg } from "components/svgs/link-arrow";
import {
  AdminPagePartnerOptions,
  ClearCertification,
  CreateCertification,
  CreateGuide,
  PassAudit,
  RemoveGuide,
  RemoveUserAccount,
  RemoveUserAccounts,
} from "for-admin";
import { SendSignRequestEmail } from "for-admin/request-sign";
import { ResendConfirmationEmail } from "for-admin/resend-confirmation-email";
import { SendCertCompletedEmail } from "for-admin/send-cert-completed";
import { SendPassdResetEmail } from "for-admin/send-passwd-reset-email";
import { SendWelcomeEmail } from "for-admin/send-welcome-email";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ROUTE } from "variables";
import "./index.scss";

export function AdminPage() {
  const { loading, setLoading } = useLoadingState();
  const [abn, setAbn] = useState();
  const [organisation, setOrganisation] = useState({});
  const [supplyChainItem, setSupplyChainItem] = useState();
  const [partnerDetail, setPartnerDetail] = useState();
  const [primeDetail, setPrimeDetail] = useState();
  const [abnRef, setAbnRef] = useState();
  const [showResults, setShowResults] = useState(null);
  const [summary, setSummary] = useState({
    partners: 0,
    primes: 0,
    suppliers: 0,
    sales: 0,
    completedCerts: 0,
    commissions: 0,
    campaigns: 0,
  });

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    setupDetails();
  }, []);

  useEffect(() => {
    setLoading(false);
    if (!!params.ABN) {
      getOrganisation(params.ABN).then((result) => {
        if (!!result) {
          setOrganisation(result);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!!abnRef && !!params.ABN) {
      abnRef.value = params.ABN;
    }
  }, [abnRef]);

  const abnSearch = async () => {
    setLoading(true);

    try {
      if (!!abnRef) {
        abnRef.value = abn.replace(/ /g, "");
      }
      setAbn(abn.replace(/ /g, ""));

      const ABN = abn.replace(/ /g, "");

      let org = await getOrganisation(ABN);
      let customer = await getCustomer(ABN);
      let partner = await getPartner(ABN);

      // console.log(org, customer, partner);

      if (!org) {
        setShowResults(false);
        setLoading(false);
        return;
      }

      org = {
        ...org,
        ...customer,
      };

      if (!!customer) {
        org.isCustomer = true;
        org.accountRegistered = true;
      } else {
        org.accountRegistered = false;
      }

      console.log(org);

      setOrganisation(org);

      setShowResults(true);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const setupDetails = async () => {
    setLoading(true);

    const completedCerts = (
      (
        await searchCertificationProgressesByABN("", {
          status: {
            eq: "COMPLETED",
          },
        })
      ).items || []
    ).length;
    const sales = (await searchSalesByABN("")).length;

    setSummary({
      completedCerts,
      sales,
    });
    setLoading(false);
  };

  return (
    <>
      <MetaDescription
        pageTitle="Admin panel"
        pageDescription="Admin panel for CyberCert"
      />

      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[{ to: ROUTE.ROOT, name: "Admin" }]}
                  />

                  <h1 className="title">Dashboard</h1>
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <DashboardRecentActivitySection />

            <div className="admin-section-title">
              <p>Quick Search</p>
            </div>

            <div className="content-admin admin-page">
              <div className="heading">
                <h2 className="content-admin-title">Accounts</h2>
                <p>Search for an account to view details</p>
              </div>

              <FormInput
                controlId="ABN"
                label="Organisation ABN"
                type="search"
                name="abn"
                value={abn}
                maxLength="11"
                onChange={(event) => setAbn(event.target.value)}
                errorTxt={""}
                pValidation={null}
                pSetValidation={() => {}}
                setRef={setAbnRef}
                required
              />

              <div className="mt-30">
                <Button className="btn btn-warning-gd" onClick={abnSearch}>
                  Search
                </Button>
              </div>

              {
                {
                  true: (
                    <div className="mt-30">
                      <div className="org-details">
                        <p className="label">Account details</p>
                        <p className="org-name">
                          <Link
                            to={ROUTE.VIEW_ACCOUNT.replace(
                              ":id",
                              organisation?.ABN
                            )}
                          >
                            {organisation?.name}{" "}
                          </Link>
                          {organisation?.isCustomer && (
                            <span className="account-type supplier-type">
                              Customer
                            </span>
                          )}
                        </p>
                        <p>
                          <b>ABN</b> {organisation?.ABN}
                        </p>
                        <p className="capitalize">
                          <b>Industry Sector</b>{" "}
                          {organisation?.industry || "None"}
                        </p>
                        <p>
                          <b>Location</b>{" "}
                          <>
                            {organisation?.addressState},{" "}
                            {organisation?.addressPostcode}
                          </>
                        </p>
                        {organisation.isCustomer && (
                          <div className="mt-30">
                            <p className="label">Customer details</p>
                            <p>
                              <b>Associated Primes</b> N/A
                            </p>
                            <p>
                              <b>Certification ID</b>{" "}
                              {organisation?.certificationId || "N/A"}
                            </p>
                            <p>
                              <b>Certification Level</b>{" "}
                              {organisation?.certificationLevel || "N/A"}
                            </p>
                            <p>
                              <b>Certification Status</b>{" "}
                              {organisation?.certification?.progress
                                ?.status || <>Not Started</>}
                            </p>
                          </div>
                        )}
                      </div>
                      {organisation?.accountRegistered ? (
                        <div className="quick-actions">
                          <p className="label">Account actions</p>
                          <div className="f-row-start-center">
                            <LinkArrowSvg />
                            <CreateCertification ABN={organisation?.ABN} />
                          </div>
                          <div className="f-row-start-center">
                            <LinkArrowSvg />
                            <ClearCertification ABN={organisation?.ABN} />
                          </div>
                          <div className="f-row-start-center">
                            <LinkArrowSvg />
                            <PassAudit ABN={organisation?.ABN} />
                          </div>
                          <div className="f-row-start-center">
                            <LinkArrowSvg />
                            <CreateGuide ABN={organisation?.ABN} />
                          </div>
                          <div className="f-row-start-center">
                            <LinkArrowSvg />
                            <RemoveGuide ABN={organisation?.ABN} />
                          </div>
                        </div>
                      ) : (
                        <div className="mt-30">
                          <div className="org-details">
                            <div className="f-row-start-center">
                              No actions allowed. This account is not
                              registered.
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ),
                  false: <div className="mt-30">Account not found.</div>,
                }[showResults]
              }

              <div className="quick-actions">
                <p className="label">Admin options</p>
                <div className="f-row-start-center">
                  <Link to={ROUTE.ADD_ACCOUNT}>
                    <LinkArrowSvg />
                    Add an account
                  </Link>
                </div>
                <div className="f-row-start-center">
                  <RemoveUserAccount />
                </div>
                <div className="f-row-start-center">
                  <RemoveUserAccounts />
                </div>

                <div className="f-row-start-center">
                  <Link to={ROUTE.ACCOUNTS}>
                    <LinkArrowSvg />
                    View all accounts
                  </Link>
                </div>
              </div>

              <div className="quick-actions">
                <p className="label">Email options</p>

                <div className="f-row-start-center">
                  <ResendConfirmationEmail />
                </div>

                <div className="f-row-start-center">
                  <SendWelcomeEmail />
                </div>

                <div className="f-row-start-center">
                  <SendPassdResetEmail />
                </div>

                <div className="f-row-start-center">
                  <SendSignRequestEmail />
                </div>

                <div className="f-row-start-center">
                  <SendCertCompletedEmail />
                </div>
              </div>
            </div>

            <div className="admin-section-title">
              <p>Accounts Summary</p>
            </div>
            <div className="grid-thirds">
              <div className="grid-column">
                <div className="title">
                  <h2 className="type">Partners</h2>
                  <AdminPagePartnerOptions />
                </div>
                <div className="value">
                  <p>{summary.partners}</p>
                </div>
              </div>
              <div className="grid-column">
                <div className="title">
                  <Link to="/">
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>{summary.primes}</p>
                </div>
              </div>
              <div className="grid-column">
                <div className="title">
                  <h2 className="type">Suppliers</h2>
                  <Link to="/">
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>{summary.suppliers}</p>
                </div>
              </div>
            </div>

            <div className="admin-section-title">
              <h2>Ongoing Items</h2>
            </div>
            <div className="grid-thirds">
              <div className="grid-column">
                <div className="title">
                  <p className="type">Sales</p>
                  <Link to={ROUTE.SALES}>
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>{summary.sales}</p>
                </div>
              </div>
              <div className="grid-column">
                <div className="title">
                  <p className="type">Commissions</p>
                  <Link to={ROUTE.COMMISSIONS}>
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>{summary.commissions}</p>
                </div>
              </div>
              <div className="grid-column">
                <div className="title">
                  <p className="type">Campaigns</p>
                  <Link to={ROUTE.CAMPAIGNS}>
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>{summary.campaigns}</p>
                </div>
              </div>
            </div>

            <div className="admin-section-title">
              <p>Progress</p>
            </div>
            <div className="grid-thirds">
              <div className="grid-column">
                <div className="title">
                  <p className="type">Completed Certifications</p>
                  <Link to={ROUTE.CERTIFICATIONS}>
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>{summary.completedCerts}</p>
                </div>
              </div>
              <div className="grid-column">
                <div className="title">
                  <p className="type">Reports</p>
                  <Link to={ROUTE.REPORTS}>
                    <p className="view">View all</p>
                  </Link>
                </div>
                <div className="value">
                  <p>0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
