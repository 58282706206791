import { makeGetFunction } from "api/utils";
import { listRecommendationTools as listAPI } from "graphql/queries";

export const getRecommendationTool = async (ABN) => {
  let questionaire = (await listRecommendationTools(ABN))[0];
  return questionaire;
};

const listRecommendationTools = async (ABN) => {
  return (
    (
      await makeGetFunction({
        name: "listRecommendationTools",
        query: listAPI,
        params: {
          filter: {
            ABN: {
              eq: ABN,
            },
          },
        },
      })()
    )?.items || []
  );
};
