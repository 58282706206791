import {
  deleteCertificationProgress,
  getCertificationProgress,
  getCustomer,
  getOrganisation,
  searchCertificationsByABN,
  searchCertificationsByID,
} from "api";
import { delete_account, edit_account, filter_account, spinner } from "assets";
import {
  AdminSearch,
  MetaDescription,
  NavBreadcrumbList,
  Spinner,
} from "components";
import { SearchResultsWithPagination } from "components/pagination";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE } from "variables";

import { ConfirmPopup } from "components/confirm";
import { useLoadingState } from "middleware";
import { CertificationService } from "services/certification";
import "./index.scss";

const defaultFilterLevels = [1, 2, 3, 4, 5];
const defaultFilterStatuses = ["NOT_STARTED", "IN_PROGRESS", "COMPLETED"];
const defaultFilterValidities = ["active", "expired"];

const initialFilter = {
  level: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  },
  status: {
    NOT_STARTED: false,
    IN_PROGRESS: false,
    COMPLETED: false,
  },
  validity: {
    active: false,
    expired: false,
  },
};

export function CertificationsPage() {
  const { loading, setLoading } = useLoadingState();
  const [searchBy, setSearchBy] = useState("ABN");
  const [showFilter, setShowFilter] = useState(false);
  const searchResultsRef = useRef();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [filterBy, setFilterBy] = useState(initialFilter);
  const filterRef = {
    level: {
      1: useRef(),
      2: useRef(),
      3: useRef(),
      4: useRef(),
      5: useRef(),
    },
    status: {
      NOT_STARTED: useRef(),
      IN_PROGRESS: useRef(),
      COMPLETED: useRef(),
    },
    validity: {
      active: useRef(),
      expired: useRef(),
    },
  };
  const [searchRef, setSearchRef] = useState();
  const selectRef = useRef();
  const deleteRef = useRef();
  const auditRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    let filter = filterBy;
    // level
    params.level?.split(" ")?.forEach((p) => {
      if (Object.keys(initialFilter.level).includes(p)) {
        filter.level[p] = true;
      }
    });
    // status
    params.status?.split(" ")?.forEach((p) => {
      if (Object.keys(initialFilter.status).includes(p)) {
        filter.status[p] = true;
      }
    });
    // validity
    params.validity?.split(" ")?.forEach((p) => {
      if (Object.keys(initialFilter.validity).includes(p)) {
        filter.validity[p] = true;
      }
    });
    if (!!params.abn) {
      performSearch(params.abn, filter);
    } else {
      performSearch("", filter);
    }
  }, []);

  const performSearch = async (value = "", filter = filterBy) => {
    setLoading(true);
    let searchFn;
    if (searchBy === "ABN") {
      searchFn = searchCertificationsByABN;
    } else {
      searchFn = searchCertificationsByID;
    }
    const result = await searchFn(value);

    if (!!result?.items) {
      const today = new Date().getTime();
      let updatedSearch = result.items;
      for (let i = 0; i < updatedSearch.length; ++i) {
        const organisation = {
          ...(await getOrganisation(updatedSearch[i].ABN)),
          ...(await getCustomer(updatedSearch[i].ABN)),
        };

        updatedSearch[i] = {
          ...updatedSearch[i],
          orgName: organisation?.name,
          address: organisation?.address,
          attestorName: updatedSearch[i]?.progress?.firstName
            ? `${updatedSearch[i]?.progress?.firstName} ${updatedSearch[i]?.progress?.lastName}`
            : organisation?.attestorName,
          attestorRole:
            updatedSearch[i]?.progress?.role ||
            organisation?.progress?.attestorRole,
          status: updatedSearch[i]?.progress?.status || "NOT_STARTED",
          validity:
            !!updatedSearch[i]?.progress?.expiredDate &&
            today -
              new Date(updatedSearch[i]?.progress?.expiredDate).getTime() >
              0
              ? "expired"
              : "active",
        };
      }
      if (!!filter) {
        let filterLevels = Object.keys(filter.level).filter(
          (f) => filter.level[f] === true
        );
        if (filterLevels.length === 0) {
          filterLevels = defaultFilterLevels;
        }
        let filterStatuses = Object.keys(filter.status).filter(
          (f) => filter.status[f] === true
        );
        if (filterStatuses.length === 0) {
          filterStatuses = defaultFilterStatuses;
        }
        let filterValidities = Object.keys(filter.validity).filter(
          (f) => filter.validity[f] === true
        );
        if (filterValidities.length === 0) {
          filterValidities = defaultFilterValidities;
        }
        updatedSearch = updatedSearch
          .filter(
            (r) =>
              filterLevels.includes(r.level) ||
              filterLevels.includes(r.level?.toString())
          )
          .filter((r) => filterStatuses.includes(r?.progress?.status))
          .filter((r) => filterValidities.includes(r.validity));
      }
      searchResultsRef?.current?.setPages(updatedSearch);
    }

    setLoading(false);
  };

  const getSelectInputs = () => {
    return Array.from(selectRef.current.children).map(
      (child) => child.children[0].children[0].children[0]
    );
  };

  const selectAll = (e) => {
    const value = e.target.checked;
    const selectInputs = getSelectInputs();
    selectInputs.forEach((input) => {
      input.checked = value;
    });
  };

  const passAuditSelectedItems = async () => {
    setLoading(true);

    const selectedItems = getSelectInputs()
      .filter((item) => item.checked === true)
      .map((item) => item.getAttribute("id-key"));

    for (let i = 0; i < selectedItems.length; ++i) {
      const certProgress = await getCertificationProgress(selectedItems[i]);

      if (certProgress?.current !== 5) {
        console.error(
          `Illegal state: ${certProgress?.ABN} - ${certProgress?.id}`
        );
        continue;
      }

      await CertificationService.passAudit(certProgress);
    }

    window.location.reload();

    setLoading(false);
  };

  const deleteSelectedItems = async () => {
    setLoading(true);

    const selectedItems = getSelectInputs()
      .filter((item) => item.checked === true)
      .map((item) => item.getAttribute("id-key"));

    for (let i = 0; i < selectedItems.length; ++i) {
      await deleteCertificationProgress({ id: selectedItems[i] });
    }

    window.location.reload();

    setLoading(false);
  };

  const deleteSingleItem = async (id) => {
    getSelectInputs().forEach((item) => {
      if (item.getAttribute("id-key") === id) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    deleteRef.current?.show();
  };

  const filterCertifications = () => {
    let updatedFilterBy = initialFilter;
    Object.keys(initialFilter.level).forEach((f) => {
      updatedFilterBy.level[f] = filterRef.level[f]?.current?.checked;
    });
    Object.keys(initialFilter.status).forEach((f) => {
      updatedFilterBy.status[f] = filterRef.status[f]?.current?.checked;
    });
    Object.keys(initialFilter.validity).forEach((f) => {
      updatedFilterBy.validity[f] = filterRef.validity[f]?.current?.checked;
    });
    setFilterBy(updatedFilterBy);
    performSearch(searchRef, updatedFilterBy);
  };

  const handleCheckbox = (event) => {
    if (event.key === "Enter") {
      event.target.click();
    }
  };

  return (
    <>
      <MetaDescription
        pageTitle="Register of certifications"
        pageDescription="Register of certifications of CyberCert"
      />

      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.CERTIFICATIONS, name: "Certifications" },
                    ]}
                  />
                  <h1 className="title">Certifications</h1>
                  <div className="mt-30"></div>
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>

            <AdminSearch
              name="certification"
              searchBy={searchBy}
              searchFn={performSearch}
              setParentSearchRef={setSearchRef}
            />
            {searchBy === "ABN" && (
              <Button className="btn-toggle" onClick={() => setSearchBy("ID")}>
                Search by ID
              </Button>
            )}
            {searchBy === "ID" && (
              <Button className="btn-toggle" onClick={() => setSearchBy("ABN")}>
                Search by ABN
              </Button>
            )}
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <div className="account-actions mt-30">
              <button
                className="filter"
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filter_account} alt="Filter account" />
                Filter
              </button>
              <div className="manage">
                <div
                  className="action"
                  onClick={() => {
                    auditRef.current?.show();
                  }}
                >
                  Pass Audit
                  <img src={edit_account} alt="Delete account" />
                </div>
                |
                <div
                  className="action"
                  onClick={() => {
                    deleteRef.current?.show();
                  }}
                >
                  Delete
                  <img src={delete_account} alt="Delete account" />
                </div>
              </div>
            </div>

            {showFilter ? (
              <div className="mt-30 content-admin">
                <div className="heading">
                  <h2 className="content-admin-title">Filter Certifications</h2>
                </div>
                <div className="filter-label">Filter by Level</div>
                <div className="filter-checkbox">
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.level[1]}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Level 1</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.level[2]}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Level 2</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.level[3]}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Level 3</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.level[4]}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Level 4</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.level[5]}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Level 5</span>
                  </label>
                </div>
                <div className="filter-label">Filter by Status</div>
                <div className="filter-checkbox">
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.status.NOT_STARTED}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Not Started</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.status.IN_PROGRESS}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">In Progress</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.status.COMPLETED}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Completed</span>
                  </label>
                </div>
                <div className="filter-label">Filter by Validity</div>
                <div className="filter-checkbox">
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.validity.active}
                        type="checkbox"
                        name=""
                      />
                    </div>
                    <span className="fake-checkbox">Active</span>
                  </label>
                  <label>
                    <div className="fake-input">
                      <input
                        ref={filterRef.validity.expired}
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </div>
                    <span className="fake-checkbox">Expired</span>
                  </label>
                </div>

                <div className="filter-controls">
                  <Button
                    className="btn btn-warning-gd"
                    onClick={filterCertifications}
                  >
                    Filter
                  </Button>
                  <Button
                    className="btn clear-btn"
                    onClick={async () => {
                      const filterRefs = Object.keys(filterRef).map(
                        (k) => filterRef[k]
                      );

                      filterRefs.forEach((fRef) => {
                        Object.keys(fRef).forEach((k) => {
                          if (!!fRef[k].current) {
                            fRef[k].current.checked = false;
                          }
                        });
                      });

                      filterCertifications();
                    }}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            ) : null}

            <SearchResultsWithPagination
              ref={searchResultsRef}
              selectRef={selectRef}
              tableHeader={
                <thead>
                  <tr>
                    <td className="sticky-col select-col">
                      <label>
                        <input
                          onClick={selectAll}
                          onKeyDown={handleCheckbox}
                          className="fake-input"
                          type="checkbox"
                          name=""
                        />
                      </label>
                    </td>
                    <td className="sticky-col action-col"></td>
                    <td>Certificate Registration ID</td>
                    <td>ABN</td>
                    <td>Organisation Name</td>
                    <td>Level</td>
                    <td>Expiry Date</td>
                    <td>Issue Date</td>
                    <td>Certification Release</td>
                    <td>Address</td>
                    <td>Attestor Name</td>
                    <td>Attestor Role</td>
                  </tr>
                </thead>
              }
              tableRow={(item) => (
                <tr className="prime-detail">
                  <td className="sticky-col select-col">
                    <label>
                      <input
                        id-key={item.id}
                        className="fake-input"
                        type="checkbox"
                        name=""
                        onKeyDown={handleCheckbox}
                      />
                    </label>
                  </td>
                  <td className="sticky-col action-col">
                    <div className="edit-delete">
                      <img
                        src={edit_account}
                        alt="Edit account"
                        onClick={() => {
                          navigate(
                            `${ROUTE.VIEW_CERTIFICATION.replace(
                              ":id",
                              item?.id
                            )}`
                          );
                        }}
                      />
                      |
                      <img
                        src={delete_account}
                        alt="Delete account"
                        onClick={() => {
                          deleteSingleItem(item.id);
                        }}
                      />
                    </div>
                  </td>

                  {searchBy === "ID" ? (
                    <td>
                      <b>{item.certID || item.id}</b>
                    </td>
                  ) : (
                    <td>{item.certID || item.id}</td>
                  )}
                  {searchBy === "ABN" ? (
                    <td>
                      <b>{item.ABN}</b>
                    </td>
                  ) : (
                    <td>{item.ABN}</td>
                  )}
                  <td className="capitalize">{item.orgName || <>N/A</>}</td>
                  <td>{item.level}</td>
                  <td>{item.expiredDate}</td>
                  <td>{item.createdAt}</td>
                  <td>NA</td>
                  <td>{item.address}</td>
                  <td className="capitalize">{item.attestorName}</td>
                  <td>{item.attestorRole}</td>
                </tr>
              )}
            />
          </div>
        </div>
      </div>

      <ConfirmPopup
        ref={deleteRef}
        title="Delete certification register"
        body="Confirm selected deleting certification registers"
        confirmTxt="Delete"
        confirmFn={deleteSelectedItems}
      />

      <ConfirmPopup
        ref={auditRef}
        title="Approve audit pass for certification register"
        body="Confirm selected passing audit certification registers"
        confirmTxt="Confirm"
        confirmFn={passAuditSelectedItems}
      />
    </>
  );
}
