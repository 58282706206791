import { getOrganisation, getUserByEmail } from "api";
import { spinner } from "assets";
import { FormInput, Spinner } from "components";
import { LinkArrowSvg } from "components/svgs/link-arrow";
import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthService } from "services";

export function RemoveUserAccount() {
  const [showPanel, setShowPanel] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [search, setSearch] = useState();
  const [searchRef, setSearchRef] = useState();
  const [searchResults, setSearchResults] = useState();
  const [searchErr, setSearchErr] = useState("na");
  const [internalLoading, setInternalLoading] = useState(false);
  const [deleteOk, setDeleteOk] = useState("na");

  const searchResultsRef = useRef();

  const handleClose = () => {
    setShowPanel(false);
    setSearchResults(null);
    setDeleteOk("na");
  };
  const handleShow = () => {
    setShowPanel(true);
    setDeleteOk("na");
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setSearchResults(null);
    setDeleteOk("na");
  };
  const handleShowConfirm = () => {
    const accountsToRemove = getSelectedAccounts();
    if (accountsToRemove.length > 0) {
      setShowConfirm(true);
      setDeleteOk("na");
    }
  };

  const handleDelete = async () => {
    setDeleteOk("na");
    setShowConfirm(false);
    setInternalLoading(true);

    try {
      const accountsToRemove = getSelectedAccounts();

      const searchesToRemove = searchResults.filter((res) =>
        accountsToRemove.includes(res.id)
      );

      let ok;
      await Promise.all(
        searchesToRemove.map(async (toRemove) => {
          ok = await AuthService.adminClearUser(toRemove);
        })
      );
      setDeleteOk(ok);
    } catch (err) {
      console.log(err);
      setDeleteOk("error");
    }

    setSearchResults(null);
    setInternalLoading(false);
  };

  const searchByEmail = async () => {
    setInternalLoading(true);
    let result = (await getUserByEmail(search))?.items
      ?.filter((item) => !!item)
      .map((item) => {
        if (item.owner?.includes("facebook")) {
          return {
            ...item,
            provider: "Facebook",
          };
        } else if (item.owner?.includes("google")) {
          return {
            ...item,
            provider: "Google",
          };
        } else {
          return {
            ...item,
            provider: "Email",
          };
        }
      });

    if (!!result && result.length > 0) {
      result = await Promise.all(
        result.map(async (r) => {
          const organisation = await getOrganisation(r.ABN);
          return {
            ...r,
            organisation,
          };
        })
      );

      setSearchResults(result);
      setSearchErr("success");
    } else {
      setSearchResults(null);
      setSearchErr("error");
    }
    setInternalLoading(false);
    setDeleteOk("na");
  };

  // const test = () => {
  //   Array.from(searchResultsRef?.current?.children).forEach((child) => {
  //     console.log(child.className);
  //   });
  // };

  const selectAccountToClear = (idx) => {
    Array.from(searchResultsRef?.current?.children).forEach((child, i) => {
      if (idx === i) {
        if (child.className.includes("selected")) {
          child.className = "account-search-res selectable";
        } else {
          child.className = "account-search-res selectable selected";
        }
      }
    });
  };

  const getSelectedAccounts = () => {
    return Array.from(searchResultsRef?.current?.children)
      .filter((child) => child.className.includes("selected"))
      .map((child) => child.getAttribute("id-key"));
  };

  return (
    <div>
      <Button variant="btn btn-admin-settings" onClick={handleShow}>
        <LinkArrowSvg />
        Clear account
      </Button>

      <Modal show={showPanel} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Clear User Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {internalLoading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="email"
            label="Search by email"
            type="search"
            name="email"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            setRef={setSearchRef}
            required
          />

          {!!searchResults && (
            <div className="searchResults" ref={searchResultsRef}>
              {searchResults.map((searchRes, i) => (
                <div
                  className="account-search-res selectable"
                  onClick={() => selectAccountToClear(i)}
                  id-key={searchRes.id}
                >
                  <p>
                    <b>Provider:</b> {searchRes?.provider}
                  </p>
                  <p>
                    <b>Email:</b> {searchRes?.email}
                  </p>
                  <p className="capitalize">
                    <b>Name:</b> {searchRes?.firstName} {searchRes?.lastName}
                  </p>
                  <p>
                    <b>Organisation:</b> {searchRes?.ABN}
                  </p>
                  <p className="capitalize">
                    <b>Organisation Name:</b> {searchRes?.organisation?.name}
                  </p>
                  <p>
                    <b>Certification Level:</b>{" "}
                    {searchRes?.organisation?.certificationLevel}
                  </p>

                  <hr />
                </div>
              ))}

              <p>Select the account(s) you want to delete.</p>
            </div>
          )}

          <div className={`form-validation ${deleteOk}`}>
            <div className="validation-txt">
              <p className="v-text success">Delete success.</p>
              <p className="v-text error">Delete failed.</p>
            </div>
          </div>

          <div className={`form-validation ${searchErr}`}>
            <div className="validation-txt">
              <p className="v-text error">Account does not exist.</p>
            </div>
          </div>

          <div className="mt-30">
            <Button className="btn btn-warning-gd" onClick={searchByEmail}>
              Search
            </Button>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleShowConfirm}
            disabled={!searchResults}
          >
            Delete user
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Clear User Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Confirm deleting this user: {searchResults?.email}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={handleDelete}
            disabled={!searchResults}
          >
            Delete user
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
