import {
  MAINTENANCE_INTERVAL,
  cancelMaintenance,
  scheduleMaintenance,
} from "api";
import { updateActiveApp } from "api/graphql/app/mutations";
import { spinner } from "assets";
import { FormInput } from "components/form-input";
import { Spinner } from "components/loading";
import { Asterisk } from "components/required-asterisk";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { parseDateTime } from "services";

export function AdminAppSettingMaintenanceMode({ app, navigate = () => { } }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirm = async () => {
    if (app?.mode === "MAINTENANCE") {
      await updateActiveApp({
        mode: "DEFAULT",
      });
    } else if (app?.mode === "DEFAULT") {
      await updateActiveApp({
        mode: "MAINTENANCE",
      });
    }
    setShow(false);
    navigate(0);
  };

  return (
    <>
      <div>
        <p>Current mode: {app?.mode}</p>

        <Button variant="btn btn-warning-gd" onClick={handleShow}>
          Toggle maintenance
        </Button>
        {"    "}

        <ScheduleMaintenanceMode />

        {!!app?.maintenanceSchedule && <ScheduledDetail app={app} />}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Maintenance Mode</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Set current app mode to{" "}
          {app?.mode === "MAINTENANCE" ? "DEFAULT" : "MAINTENANCE"}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ScheduledDetail({ app }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(`APP ${JSON.stringify(app)}`);
  }, [app]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleConfirm = async () => {
    setLoading(true);

    await cancelMaintenance();

    handleClose();
    setLoading(false);
    window.location.reload();
  };

  const switchTimeFormat = async () => {
    setLoading(true);

    await updateActiveApp({
      maintenanceScheduleDisplayTimeFormat:
        app?.maintenanceScheduleDisplayTimeFormat === 24 ? 12 : 24,
    })

    setLoading(false);
  };

  return (
    <div>
      <div className="scheduled-detail">
        <div>
          <Asterisk />
          <p>
            A maintenance was schedule at{" "}
            {parseDateTime(app?.maintenanceSchedule)}.
          </p>
        </div>

        <Button variant="btn btn-warning-gd" onClick={handleShow}>
          Cancel schedule
        </Button>
      </div>

      <div className="scheduled-detail">
        <div>
          <p>
            Maintenance notification time format:{" "}
            {app?.maintenanceScheduleDisplayTimeFormat === 24
              ? "24 hours"
              : "12 hours"}
            .
          </p>
        </div>

        <Button variant="btn btn-warning-gd" onClick={() => switchTimeFormat()}>
          Switch Format
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Schedule</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}
          Cancel maintenance schedule at{" "}
          {parseDateTime(app?.maintenanceSchedule)}?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const INITIAL_SCHEDULE = {
  time: 12,
  interval: MAINTENANCE_INTERVAL.HOUR,
};

function ScheduleMaintenanceMode() {
  const [show, setShow] = useState(false);
  const [input, setInput] = useState();
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setInput(INITIAL_SCHEDULE);
    setShow(true);
  };

  const handleClose = () => {
    setInput(null);
    setShow(false);
  };

  const handleConfirm = async () => {
    setLoading(true);

    await scheduleMaintenance(input);

    handleClose();
    setLoading(false);
    window.location.reload();
  };

  const handleInput = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Button variant="btn btn-warning-gd" onClick={handleShow}>
        Schedule maintenance
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule a Maintenance</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <div>Schedule a maintenance in</div>

          <FormInput
            controlId="timeInput"
            label="Time"
            type="text"
            name="time"
            onChange={handleInput}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={INITIAL_SCHEDULE.time}
            required
          />

          <FormInput
            controlId="intervalInput"
            options={Object.values(MAINTENANCE_INTERVAL)}
            label="Interval"
            type="select"
            name="interval"
            value={INITIAL_SCHEDULE.interval}
            onChange={handleInput}
            required
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
