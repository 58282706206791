import { getOrganisation } from "api";
import { spinner } from "assets";
import { NavBreadcrumbList, Spinner } from "components";
import { AccountSettings, AppSettings, CertificationSettings } from "for-admin";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ROUTE } from "variables";
import "./index.scss";

export function SettingsPage() {
  const [abn, setAbn] = useState();
  const [organisation, setOrganisation] = useState({});
  const [abnRef, setAbnRef] = useState();
  const { loading, setLoading } = useLoadingState();

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    setLoading(false);
    if (!!params.ABN) {
      getOrganisation(params.ABN).then((result) => {
        if (!!result) {
          setOrganisation(result);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!!abnRef && !!params.ABN) {
      abnRef.value = params.ABN;
    }
  }, [abnRef]);

  return (
    <>
      {loading.loading && (
        <div className="overlay">
          <Spinner jsonTemplate={spinner} />
        </div>
      )}

      <div className="main-dashboard-container">
        <div className="dashboard-header-admin">
          <div className="container-md">
            <div className="df-row">
              <div className="col-wl">
                <div className="card-wl">
                  <NavBreadcrumbList
                    items={[
                      { to: ROUTE.ROOT, name: "Admin" },
                      { to: ROUTE.SETTINGS, name: "Settings" },
                    ]}
                  />
                </div>
                <div className="col-pg back-to-dashboard"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="level-certification-block">
          <div className="container-md">
            <h1 className="title">Settings</h1>

            <div className="mt-30">
              <AppSettings />
              <CertificationSettings />
              <AccountSettings />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
