import { API, graphqlOperation } from "aws-amplify";

export const makeGetFunction = ({
  name,
  query,
  params = {},
  authMode = "AMAZON_COGNITO_USER_POOLS",
}) => {
  return async () => {
    try {
      const result = await API.graphql({
        ...graphqlOperation(query, params),
        authMode,
      });
      // console.log(`${name} ${JSON.stringify(result)}`);
      return result.data[name];
    } catch (err) {
      if (!!err?.data && !!err?.data[name]) {
        return err.data[name];
      }
      // console.log(`${name} ${JSON.stringify(err)}`);
      console.log(err);
      console.error(`${name} not exist.`);
      return undefined;
    }
  };
};

export const makeCreateFunction = ({
  name,
  query,
  params = {},
  authMode = "AMAZON_COGNITO_USER_POOLS",
}) => {
  return async () => {
    try {
      const result = await API.graphql({
        ...graphqlOperation(query, params),
        authMode,
      });
      return result.data[name];
    } catch (error) {
      console.error("Create failed.");
      // console.log(query);
      // console.log(params);
      console.error(error);
      return undefined;
    }
  };
};

export const makeDeleteFunction = ({
  name,
  query,
  params = {},
  authMode = "AMAZON_COGNITO_USER_POOLS",
}) => {
  return async () => {
    try {
      const result = await API.graphql({
        ...graphqlOperation(query, params),
        authMode,
      });
      return result;
    } catch (error) {
      console.error("Delete failed.");
      console.log(error);
      return undefined;
    }
  };
};

export const makeUpdateFunction = ({
  name,
  query,
  params = {},
  authMode = "AMAZON_COGNITO_USER_POOLS",
}) => {
  return async () => {
    try {
      const result = await API.graphql({
        ...graphqlOperation(query, params),
        authMode,
      });
      return result.data[name];
    } catch (error) {
      console.error(error);
      console.error("Update failed.");
      return undefined;
    }
  };
};

export const makeSubscriptionFunction = ({
  query,
  params = {},
  onAction,
  authMode = "AMAZON_COGNITO_USER_POOLS",
}) => {
  return () => {
    try {
      const subscription = API.graphql({
        ...graphqlOperation(query, params),
        authMode,
      }).subscribe({
        next: ({ provider, value }) => onAction({ provider, value }),
        error: (error) => console.warn(error),
      });
      return () => subscription.unsubscribe();
    } catch (error) {
      console.error(error);
      return () => {};
    }
  };
};
