export const COMMISSION_ITEMS = {
  GUIDE: 0,
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5,
};

export const COMMISSION_TYPE = {
  PURCHASE: "Purchase",
  UPGRADE: "Upgrade",
  RENEW: "Renewal",
};
