import { makeGetFunction } from "api/utils";
import { getSale as getAPI, listSales } from "graphql/queries";

export const searchSalesByABN = async (ABN, filter) =>
  (
    await makeGetFunction({
      name: "listSales",
      query: listSales,
      params: {
        filter: {
          ...filter,
          supplier: {
            contains: ABN,
          },
        },
      },
    })()
  )?.items || [];

export const getSales = (id) =>
  makeGetFunction({
    name: "getSale",
    query: getAPI,
    params: {
      id,
    },
  })();
