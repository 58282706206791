import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export const RecentActivityItemDeleteModal = forwardRef((props, ref) => {
  const { deleteFn } = props;

  const [show, setShow] = useState(false);
  const [item, setItem] = useState();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleConfirm = () => {
    // delete
    if (!!deleteFn) {
      deleteFn(item);
    }
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    show(item) {
      if (!!item) {
        setItem(item);
        handleShow();
      }
    },

    close() {
      handleClose();
    },
    delete() {
      handleConfirm();
    },
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete activity record</Modal.Title>
      </Modal.Header>

      <Modal.Body>Confirm delete this activity record?</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>

        <Button variant="primary" onClick={handleConfirm}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
