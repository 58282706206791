import { spinner } from "assets";
import { FormInput } from "components/form-input";
import { Spinner } from "components/loading";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export function PartnerOptionAddUser({
  input,
  handleInput,
  showPanel,
  closePanel,
  showCfmPanel,
  closeCfmPanel,
  process,
  confirm,
}) {
  const [loading, setLoading] = useState();
  const [err, setErr] = useState(null);

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    // Add any setup logic here if needed
  }

  const handleProcess = async () => {
    setLoading(true);

    handleConfirm();

    setLoading(false);
  };

  const handleConfirm = async () => {
    setErr(null);

    setLoading(true);

    if (!["email", "ABN"].every((k) => !!input[k])) {
      setErr("Please fill in all required fields.");
    } else {
      let result = await confirm(input);
      setErr(result || undefined);
    }

    setLoading(false);
  };

  return (
    <>
      <Modal show={showPanel} onHide={closePanel}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <div className="overlay">
              <Spinner jsonTemplate={spinner} />
            </div>
          )}

          <FormInput
            controlId="addUserABN"
            label="ABN"
            type="text"
            name="ABN"
            value={input?.ABN}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addUserEmail"
            label="Email"
            type="text"
            name="email"
            value={input?.email}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
            required
          />

          <FormInput
            controlId="addUserPhone"
            label="Phone"
            type="text"
            name="phone"
            value={input?.phone}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          <FormInput
            controlId="addUserFirstName"
            label="Full Name"
            type="text"
            name="fullName"
            value={input?.firstName}
            onChange={handleInput}
            errorTxt={""}
            pValidation={null}
            pSetValidation={() => {}}
          />

          {err !== null && (
            <div className={`form-validation ${!!err ? "error" : "success"}`}>
              <div className="validation-txt">
                {!!err ? (
                  <p className="v-text error">{err}</p>
                ) : (
                  <p className="v-text success">User added successfully.</p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleProcess}>
            Process
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
